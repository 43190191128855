<div id="div">
  <form [formGroup]="updateVersion" (ngSubmit)="onSubmit()">

    <h1 id="h1" class="text-center my-5 py-4">Update Version</h1>

    <!-- <h1>Quiz_id :- {{QUIZ_ID2}}</h1> -->
    <mat-form-field appearance="fill" class="input-field">
      <mat-label>AppName</mat-label>
      <input matInput formControlName="appName">
     
    </mat-form-field><br><br>

    <mat-form-field appearance="fill" class="input-field">
      <mat-label>Version</mat-label>
      <input matInput formControlName="version">
    </mat-form-field><br><br>
    
    <p>
      <!-- <mat-slide-toggle formControlName="status">Status</mat-slide-toggle> -->
      <mat-slide-toggle formControlName="status">Status</mat-slide-toggle>
      <!-- <mat-checkbox color="primary"><input hidden formControlName="isActive"> Status</mat-checkbox>  -->
    </p>

    <!-- <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" formControlName="status" [checked] = false>
    </div> -->

    <button mat-raised-button color="primary">Submit</button>

    <!-- <button mat-raised-button color="primary" class="ml-3" (click)="clearData()">Reset</button> -->

    <button mat-raised-button color="warn" class="ml-3" (click)="clicktobackList()">Cancel</button>
  </form>
</div>

<mat-card *ngIf="_loaderService.isLoading | async" style="display: flex; justify-content: center; align-items: center;">
  <mat-progress-spinner 
    color="primary" 
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>