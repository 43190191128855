import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { optimiseDeleteApi, optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyUserService {

  constructor(private _http: HttpClient) { }

  public url = "https://englearn.sparkleweb.co.in/api/account";
  
  // /api/account/getuserlist?PageIndex=1&PageSize=5

  // getUserList(  pageIndex:any, pageSize:any) {
    getUserList(pageSize:number,pageIndex:number ,search:any ) {
    debugger;
    //return optimiseGetApi(`${this.url +"/getuserlist?PageIndex="}${pageIndex}&PageSize=${pageSize}`);
    //  return this._http.get(this.url+"/getuserlist");
    
    return optimiseGetApi(`${this.url+"/getuserlist?PageIndex="}${pageIndex}&PageSize=${pageSize}&Search=${search}`);

  }

  // searchUserList(search:any)
  // {

  // }

  postUserList(data:any){
    debugger
    //  return this._http.post(this.url+"/register",data);
    // return optimisePostApi(this.url+"/register-admin",data);
    return optimisePostApi(this.url+"/register",data);
  }

  deleteUserList(data:any ){
    debugger
    // return this._http.delete(`${this.url+"/deleteuserbyguid?GUID="}${id}`);
    // return optimiseDeleteApi(`${this.url+"/deleteuserbyguid?GUID="}${id}`);
    return this._http.put(`${this.url+"/deleteuser"}`,data);
  }

  getList(id:any){
    // return this._http.get(`${this.url+"/getuserbyguid?GUID="}${id}`);
    return optimiseEditApi(`${this.url+"/getuserbyguid?GUID="}${id}`);
  }

  updateUserList(data:any){
    debugger
    // return this._http.put(`${this.url+"/updateuserdetails"}`,data);
    return optimiseUpdateApi(`${this.url+"/updateuserdetails?devicetype=website"}`,data);
  }

  dropdownFilter(pageIndex:number,pageSize:number,searchLanguage:string)
  {
    return this._http.get(`${this.url + "/getuserlist?PageIndex="}${pageIndex}&PageSize=${pageSize}&Searchbylanguage=${searchLanguage}`);
    // return optimiseGetApi(this.url + "/getuserlist");
  }
  

}
