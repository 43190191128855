  <div id="div" class="container">
  <form id="form" [formGroup]="addTopicList" (ngSubmit)="onSubmit()">
    <h1 id="h1" class="text-center my-4 py-4">
      {{ editMode ? "Update" : "Add" }} Topic
    </h1>

    <!-- <h2 class="text-muted">Course Id :-  {{COURSEID2}}</h2> -->
    <mat-form-field appearance="fill" class="input-field">
      <mat-label> Name</mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="hasError('name', 'required')"
        >Please enter Topic Name
      </mat-error> </mat-form-field
    ><br /><br />

    <mat-form-field appearance="fill" class="input-field">
      <mat-label>Topic Type</mat-label>
      <mat-select disableRipple formControlName="topicType">
        <mat-option value="List">List</mat-option>
        <mat-option value="Tiles">Tiles</mat-option>
      </mat-select>
      <mat-error *ngIf="hasError('topicType', 'required')"
        >Please select Topic Type</mat-error> 
      </mat-form-field><br/>

    <!-- <mat-form-field appearance="fill" class="input-field">
      <mat-label> TopicType</mat-label>
      <input matInput formControlName="topicType">
      <mat-error *ngIf="hasError('topicType', 'required')">Please enter Topic Type</mat-error>
    </mat-form-field><br> -->

    <mat-form-field appearance="fill" class="input-field" hidden>
      <mat-label>Course Id</mat-label>
      <input matInput formControlName="courseID" />
      <mat-error *ngIf="hasError('courseID', 'required')"
        >Please enter Course Id</mat-error
      >
    </mat-form-field>

    <br />

    <mat-form-field appearance="fill" class="input-field">
      <mat-label> Hindi Name</mat-label>
      <input matInput formControlName="hindiName" />
      <mat-error *ngIf="hasError('hindiName', 'required')"
        >Please enter Hindi Topic Name</mat-error
      > </mat-form-field
    ><br /><br />

    <mat-form-field appearance="fill" class="input-field">
      <mat-label> Gujarati Name</mat-label>
      <input matInput formControlName="gujName" />
      <mat-error *ngIf="hasError('gujName', 'required')"
        >Please enter Gujarati Topic Name
      </mat-error> </mat-form-field
    ><br /><br />

    <mat-form-field appearance="fill" class="input-field">
      <mat-label>Sort Order</mat-label>
      <input matInput formControlName="sortOrder" />
      <mat-error *ngIf="hasError('sortOrder', 'required')"
        >SortOrder is required</mat-error
      >
      <mat-error *ngIf="hasError('sortOrder', 'pattern')"
        >Only numbers are allowed</mat-error
      >
    </mat-form-field>

    <br />

       <input type="file" class="input-field" formControlName="imageFile" (change)="onImageSubmit($event)" />
        <h2>{{previousImg}}</h2>
        <p *ngIf="previousImg">Previous Image</p>
        <img *ngIf="previousImg" src="https://englearn.sparkleweb.co.in/content/images/coursetopic/{{previousImg}}" formControlName="image" width="100px"
        height="100px">
        <!-- <img [src]="previousImg" width="100px" height="100px"> -->
        <span class="mx-2" *ngIf="previousImg"></span>
        <img [src]="selectedFileShow" formControlName="image" width="100px" height="100px">
        <h2>{{selectedFileName}}</h2>


    <p>
      <!-- <mat-checkbox formControlName="isActive">Status</mat-checkbox> -->
      <mat-slide-toggle formControlName="isActive">Status</mat-slide-toggle>
    </p>
    <br />
    <button mat-raised-button color="primary" type="submit">
      {{ editMode ? "Update" : "Submit" }}
    </button>
    <button mat-raised-button color="warn" class="mx-1" type="reset">
      Clear
    </button>
    <button mat-stroked-button color="primary" (click)="clicktobackList()">
      Cancel
    </button>
  </form>
</div>
<!-- loading spinner -->
<div
  class="spinner"
  *ngIf="_loaderService.isLoading | async"
  style="display: flex; justify-content: center; align-items: center"
>
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
