//import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyCourseService } from '../myCourse.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSelectedFile, onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';



@Component({
  selector: 'app-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.css']
})
export class AddCourseComponent implements OnInit {

  selectedFile: any;
  previousImg: any;
  selectedFileName: any;
  autoIncreaseSortOrder: any;
  storeSortOrder: any;
  getSortOrder: any;
  parseSortOrder: any;
  storeAutoIncreaseSortOrder: any;
  getAutoIncreaseSortOrder: any = localStorage.getItem('CourseAutoIncrease');
  selectedFileShow: any;
  public editMode: boolean = false;
  imageSrc: string | undefined;
  form: any;
  images: any;
  url: any = "https://englearn.sparkleweb.co.in/content/images/course/";




  constructor(private _fb: FormBuilder, private _router: Router, private _courseService: MyCourseService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) { }

  addCourseList = new FormGroup({
    name: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    image: new FormControl(''),
    imageFile: new FormControl(''),
    shortDescription: new FormControl('', [Validators.required]),
    isActive: new FormControl(true, [Validators.required]),
    hindiName: new FormControl('', [Validators.required]),
    hindiShortDescription: new FormControl('', [Validators.required]),
    gujName: new FormControl('', [Validators.required]),
    gujShortDescription: new FormControl('', [Validators.required]),
    sortOrder: new FormControl(localStorage.getItem('CourseSortOrder') == null ? 1 : this.getAutoIncreaseSortOrder, [Validators.required, Validators.pattern(/^[0-9]\d*$/)])
  });

  // image: any;

  public hasError = (controlName: string, errorName: string) => {
    return this.addCourseList.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {

    this._courseService.getList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {
      this.editMode = true;
      console.log(result);
      console.log(result.image);
      this.previousImg = result.image;
      this.addCourseList = new FormGroup({
        id: new FormControl(result['id']),
        name: new FormControl(result['name'], [Validators.required]),
        type: new FormControl(result['type'], [Validators.required]),
        image: new FormControl(result['image']),
        imageFile: new FormControl(result['imageFile']),
        shortDescription: new FormControl(result['shortDescription'], [Validators.required]),
        isActive: new FormControl(result['isActive'], [Validators.required]),
        hindiName: new FormControl(result['hindiName'], [Validators.required]),
        hindiShortDescription: new FormControl(result['hindiShortDescription'], [Validators.required]),
        gujName: new FormControl(result['gujName'], [Validators.required]),
        gujShortDescription: new FormControl(result['gujShortDescription'], [Validators.required]),
        sortOrder: new FormControl(result['sortOrder'], [Validators.required, Validators.pattern(/^[0-9]\d*$/)])
      });
    });
  }

  //image event //
  onImageSubmit(event: any) {
    debugger;
    this.selectedFile = <File>event.target.files[0];
    this.selectedFileName = onSelectedFile(event);

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFileShow = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    // if(event.target.files){
    //   var reader = new FileReader();
    //   reader.readAsDataURL(event.target.files[0]);
    //   reader.onload=(e:any) => {
    //     this.selectedFile = e.target.result;
    //   }
    // }
  }



  // submit data //
  onSubmit() {
    debugger

    if (this.addCourseList.invalid) {
      return;
    }
    debugger;
    if (this._activatedRoute.snapshot.params.id == "", this._activatedRoute.snapshot.params.id == "0", this._activatedRoute.snapshot.params.id == null) {

      const formData = new FormData();
      formData.append('name', this.addCourseList.value.name);
      formData.append('type', this.addCourseList.value.type);
      formData.append('shortDescription', this.addCourseList.value.shortDescription);
      formData.append('isActive', this.addCourseList.value.isActive);
      formData.append('hindiName', this.addCourseList.value.hindiName);
      formData.append('hindiShortDescription', this.addCourseList.value.hindiShortDescription);
      formData.append('gujName', this.addCourseList.value.gujName);
      formData.append('gujShortDescription', this.addCourseList.value.gujShortDescription);
      formData.append('sortOrder', this.addCourseList.value.sortOrder);
      formData.append('image', this.selectedFileName);
      formData.append('imageFile', this.selectedFile);

      this._courseService.postCourseList(formData).subscribe((result: any) => {
        console.log(this.addCourseList.value);
        this.storeSortOrder = localStorage.setItem('CourseSortOrder', this.addCourseList.value.sortOrder);
        this.getSortOrder = localStorage.getItem('CourseSortOrder');
        this.parseSortOrder = JSON.parse(this.getSortOrder);
        console.log(this.parseSortOrder);
        this.autoIncreaseSortOrder = this.parseSortOrder + 1;
        console.log(this.autoIncreaseSortOrder);
        this.storeAutoIncreaseSortOrder = localStorage.setItem('CourseAutoIncrease', this.autoIncreaseSortOrder);

        if (result.status == "success") {
          onSweetAlertSuccess(result);
          commonRouting('home/Course');
        } else if (result.status == "Already Exist") {
          onSweetAlertError(result);
        }

      })

    }

    else {
      this.addCourseList.controls['id'].setValue(this._activatedRoute.snapshot.params.id);
      const formData = new FormData();
      formData.append('id', this.addCourseList.value.id);
      formData.append('name', this.addCourseList.value.name);
      formData.append('type', this.addCourseList.value.type);
      formData.append('shortDescription', this.addCourseList.value.shortDescription);
      formData.append('isActive', this.addCourseList.value.isActive);
      formData.append('hindiName', this.addCourseList.value.hindiName);
      formData.append('hindiShortDescription', this.addCourseList.value.hindiShortDescription);
      formData.append('gujName', this.addCourseList.value.gujName);
      formData.append('gujShortDescription', this.addCourseList.value.gujShortDescription);
      formData.append('sortOrder', this.addCourseList.value.sortOrder);
      formData.append('imageFile', this.selectedFile);
      if (this.selectedFileName == undefined) {
        formData.append('image', this.previousImg);
      } else {
        formData.append('image', this.selectedFileName);
      }
      this._courseService.updateCourseList(formData).subscribe((result: any) => {
        console.log(result, "result");
        if (result.status == "success") {
          onSweetAlertSuccess(result);
          commonRouting('home/Course');
        }
      })
    }
  }

  clicktobackList() {
    commonRouting('home/Course');
  }


}






