import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';
import Swal from 'sweetalert2';
import { MyQuizService } from '../myQuiz.service';

@Component({
  selector: 'app-quiz-list',
  templateUrl: './quiz-list.component.html',
  styleUrls: ['./quiz-list.component.css']
})
export class QuizListComponent implements OnInit {
  isLoading = true;
  displayedColumns: any[] = [  'question', 'image', 'chapterID', 'sortOrder', 'option', 'action'];
  quizList: any;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  errMsg: any;
  chapterId: any;
  topicId: any;
  chaptername: any;
  public quizlist2: any;

  constructor(private _router: Router, private _quizService: MyQuizService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) { }
  totalRecords = 0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [8, 10, 25, 50];
  showFirstLastButtons = true;
  // pagecount:any ="Selecte Page per Recode";
  // PageSizeCount(){
  //   this.pageSize=this.pagecount
  // }

  handlePageEvent(event: PageEvent) {
    debugger
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    // console.log(this.dataSource.paginator.pageIndex)
    // console.log(this.dataSource.paginator.length)
    // console.log(this.dataSource.paginator.pageSize)
    console.log(this.pageSize)
    console.log(this.pageIndex)
    this.getQuizList();


  }
  ngOnInit() {
    this.topicId = localStorage.getItem('TopicId');
    this.chaptername = localStorage.getItem('ChapterName');
    this.chapterId = this._activatedRoute.snapshot.params.id;

    this.getQuizList();
  }

  dataFilter(filterValue: any) {
    this.quizList.filter = filterValue.value.trim().toLowerCase();
    this._quizService.getQuizList(this.chapterId,this.pageSize, this.pageIndex,this.quizList.filter).subscribe((result: any) => {
      console.log(result);
      // this.isLoading=true;
      this.quizList = result;
      this.isLoading = false;
      localStorage.setItem('ChapterId', JSON.stringify(this.chapterId));
      this.totalRecords = this.quizList[0].totalRecords;
      // this.quizList = new MatTableDataSource(this.quizList);
      // this.quizList.sort = this.sort;
      // this.quizList.paginator = this.paginator;
    })
  }


  getQuizList() {
    this._quizService.getQuizList(this.chapterId,this.pageSize,this.pageIndex ,'').subscribe((result: any) => {
      console.log(result);
      // this.isLoading=true;
      this.quizList = result;
      this.isLoading = false;
      localStorage.setItem('ChapterId', JSON.stringify(this.chapterId));
      this.totalRecords = this.quizList[0].totalRecords;
      this.quizList = new MatTableDataSource(this.quizList);
      this.quizList.sort = this.sort;
      // this.quizList.paginator = this.paginator;
    },
      error => {
        this.errMsg = error;
      });
  }


  deleteRow(item: any) {

    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._quizService.deleteQuizList(item).subscribe((result: any) => {
          console.log(result, "delete");
          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });

    // Swal.fire({
    //   title : "Are You Sure?",
    //   // text : "You will not be able to recover this imaginary file!",
    //   icon : "warning",
    //   showCancelButton : true,
    //   confirmButtonColor : '#3085d6',
    //   cancelButtonColor : '#d33',
    //   confirmButtonText : 'Yes, delete it!',
    //   cancelButtonText : 'Cancel'
    // }).then((result)=>{
    //   if(result.isConfirmed){
    //     this._quizService.deleteQuizList(item).subscribe((result:any) => {
    //       this.quizList = result;
    //       console.log(result, "delete");
    //       this.ngOnInit();
    //       if (result.status == "success") {
    //         Swal.fire({
    //           title: 'Deleted!',
    //           text: result.message,
    //           icon:'success'
    //         });
    //       } else if (result.status == "error") {
    //         Swal.fire({
    //           title: 'Cancelled',
    //           text: result.message,
    //           icon:'error'
    //         });
    //       }
    //     });
    //   }
    // })
  }

  backToChapterList() {
    this.topicId = localStorage.getItem('TopicId');
    this._router.navigate(['/home/Chapter', JSON.parse(this.topicId)]);
  }

  clickToAddQuiz() {
    commonRouting('home/QuizAdd');
  }

  clickToOptionList(obj: any) {
    localStorage.setItem('Question', obj.question);
    this._router.navigate(['/home/option', obj.id]);
  }

 


}
