import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MySubjectService {

  constructor() { }

  data = new Subject(); //subject allows publish and subscribe

}
