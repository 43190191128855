<div id="div" class="container">
  <form [formGroup]="addOption" (ngSubmit)="onSubmit()">

    <h1 id="h1" class="text-center  my-4 py-4">{{editMode?'Update':'Add'}} Option</h1>

    <!-- <h2 class="text-muted">Quiz id :- {{QUIZ_ID2}}</h2> -->
    <mat-form-field appearance="fill" class="input-field">
      <mat-label>Text </mat-label>
      <input matInput formControlName="text">
      <mat-error *ngIf="hasError('text', 'required')">Please enter Text</mat-error>
    </mat-form-field>
    <!-- <br><br> -->

    <mat-form-field appearance="fill" class="input-field" hidden>
      <mat-label>Quiz Id</mat-label>
      <input matInput formControlName="quizID">
      <mat-error *ngIf="hasError('quizID', 'required')">Please enter Quiz Id</mat-error>
    </mat-form-field><br><br>


    <mat-form-field appearance="fill" class="input-field">
      <mat-label>Hindi Text </mat-label>
      <input matInput formControlName="hindiText">
      <mat-error *ngIf="hasError('hindiText', 'required')">Please enter Hindi Text</mat-error>
    </mat-form-field><br><br>

    <mat-form-field appearance="fill" class="input-field">
      <mat-label>Gujarati Text </mat-label>
      <input matInput formControlName="gujText">
      <mat-error *ngIf="hasError('gujText', 'required')">Please enter Gujarati Text</mat-error>
    </mat-form-field><br><br>

    <mat-form-field appearance="fill" class="input-field">
      <mat-label>SortOrder</mat-label>
      <input matInput formControlName="sortOrder">
      <mat-error *ngIf="hasError('sortOrder', 'required')">SortOrder is required</mat-error>
      <mat-error *ngIf="hasError('sortOrder', 'pattern')">Only numbers are allowed</mat-error>
    </mat-form-field>

    <br>
    <p>
      <mat-checkbox color="primary" formControlName="correctAnswer">Correct Answer</mat-checkbox>
    </p>
    
    <br>

    <button mat-raised-button color="primary" type="submit">{{editMode?'Update':'Submit'}}</button>
    <button mat-raised-button color="warn" class="mx-2" type="reset">Clear</button>
    <button mat-stroked-button color="primary" (click)="clicktobackList()">Cancel</button>

  </form>
</div>

 <!-- loading spinner -->
 <div class="spinner" *ngIf="_loaderService.isLoading | async" style="display: flex; justify-content: center; align-items: center ; ">
  <mat-progress-spinner 
    color="primary" 
    mode="indeterminate">
  </mat-progress-spinner>
</div>