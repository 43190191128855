import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private _fb : FormBuilder ,private _myAccountService:MyAccountService ,private route:ActivatedRoute ,private router:Router) { }

  forgotPasswordForm = this._fb.group({
    email: ['',[Validators.required ,Validators.pattern]],
  });

  public error = (control:string, error:string) => {
    return this.forgotPasswordForm.controls[control].hasError(error);
  }

  ngOnInit(): void {
  }


  postForgotPassword()
  {
    if(this.forgotPasswordForm.invalid)
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Email Id is not valid',
        footer: 'Check your Email Id',
        
      });
      return 
    }
    else{
      this._myAccountService.postForgotPassword(this.forgotPasswordForm.value.email).subscribe((res)=>
    {
      console.log(res);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Mail send Successfully , Please check Mail !!',
        timer: 2000,
      });
         
      this.router.navigate(['resetpassword'])
      
  })

    }
  }

  
    }
