<table border="1">
  <tr>
    <th>ID</th>
    <th>Name</th>
    <th>Gender</th>
  </tr>
  <tr *ngFor = "let x of user">
    <td>{{x.id}}</td>
    <td>{{x.name | pipe : x.gender}}</td>
    <td>{{x.gender}}</td>
  </tr>
</table>
