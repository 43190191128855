<p>
  parent-color works!
</p>


<h2 appChangeColor>change my color</h2>

<div>
  <input type="radio" name="color" (click) = changed(color1)> Green
  <input type="radio" name="color" (click) = changed(color2)> Blue
  <input type="radio" name="color" (click) = changed(color3)> Aqua
</div>
