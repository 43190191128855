import { Component, OnInit } from '@angular/core';
import { BehaviourSubjectService } from '../behaviour-subject.service';

@Component({
  selector: 'app-behaviour-subject',
  templateUrl: './behaviour-subject.component.html',
  styleUrls: ['./behaviour-subject.component.css']
})
export class BehaviourSubjectComponent implements OnInit {

  result:any;

  constructor(private _behaviourService:BehaviourSubjectService) { }

  ngOnInit() {
    this._behaviourService.data.next(2);
    this._behaviourService.data.subscribe((e) => {
      this.result = e;
    }); // get the current value
  }

  sendData(){
    this._behaviourService.data.next(Math.random());
  }

}
