import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { optimiseDeleteApi, optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyTopicService {

  public url = "https://englearn.sparkleweb.co.in/api/coursetopic";

  constructor(private _http: HttpClient) { }

  getTopicList(id:any,pageSize:number,pageIndex:number ,search:any) {
    // return this._http.get(this.url+"/getcoursetopiclistbycourseid?courseid="+id+"&&devicetype=website").pipe(catchError(this.errorHandler));
    return optimiseGetApi(`${this.url+"/getcoursetopiclistbycourseid?PageIndex="}${pageIndex}&PageSize=${pageSize}&Search=${search}&courseid=${id}&devicetype=website`).pipe(catchError(this.errorHandler));
    // return optimiseGetApi(`${this.url+"/getcoursetopiclistbycourseid?PageIndex="}${pageIndex}&PageSize=${pageSize}&courseid=${id}&&devicetype=website`);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Unknown Server Error");
  }

  postTopicList(data: any) {
    debugger
    // return this._http.post(this.url+"/addcoursetopic", data);
    return optimisePostApi(this.url+"/addcoursetopic", data);
  }

  deleteTopicList(id: any) {
    // return this._http.delete(`${this.url+"/deletecoursetopic?id="}${id}`);
    return optimiseDeleteApi(`${this.url+"/deletecoursetopic?id="}${id}`);
  }

  getList(id: any) {
    // return this._http.get(`${this.url+"/GetCourseTopicById?id="}${id}`);
    return optimiseEditApi(`${this.url+"/GetCourseTopicById?id="}${id}`);
  }

  updateTopicList(data: any) {
    
    // return this._http.put(`${this.url+"/updatecoursetopic"}`, data);
    return optimiseUpdateApi(`${this.url+"/updatecoursetopic"}`, data);
  }

}
