import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { optimisePostApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {

  public url = "https://englearn.sparkleweb.co.in/api/Account/login";
  forgotPasswordApi =  "https://englearn.sparkleweb.co.in/api/account/forgotpassword?email=";
  resetpasswordApi  =  "https://englearn.sparkleweb.co.in/api/account/resetpassword";
  changePasswordUrl =  "https://englearn.sparkleweb.co.in/api/account/changepassword";

  constructor(private _http: HttpClient) { }

  postLoginList(data:any) {
    debugger
    // return this._http.post(this.url, data);
    return optimisePostApi(this.url, data);
  }

  postChangePassword(obj:any)
  {
    // return this._http.post(this.changePasswordUrl ,obj);
    return optimisePostApi(this.changePasswordUrl ,obj);
  }

  postForgotPassword(email:any )
  {
    debugger
    // return this._http.post(this.forgotPasswordApi + email  ,email);
    return optimisePostApi(this.forgotPasswordApi + email  ,email);
  }
  postResetpassword(data:any)
  {
    // return this._http.post(this.resetpasswordApi ,data);
    return optimisePostApi(this.resetpasswordApi ,data);
  }

}
