import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { optimiseDeleteApi, optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyOptionService {

  public url = "https://englearn.sparkleweb.co.in/api/options";

  constructor(private _http: HttpClient) { }

  getOptionList(id: any , pageIndex:number ,pageSize:number ,search:any) {
    // return this._http.get(this.url + "/getoptionsbyquizid?QuizId=" + id + "&devicetype=website").pipe(catchError(this.errorHandler));
    // return optimiseGetApi(this.url+"/getoptionsbyquizid?QuizId="+id+"&devicetype=website&pageNumber=1&pageSize=5").pipe(catchError(this.errorHandler));
        // this is pagination api 
       return optimiseGetApi(`${this.url+"/getoptionsbyquizid?PageIndex="}${pageIndex}&PageSize=${pageSize}&QuizId=${id}&Search=${search}`).pipe(catchError(this.errorHandler));

  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Unknown server error");
  }

  postOptionList(data: any) {
    // return this._http.post(this.url+"/addoption", data);
    return optimisePostApi(this.url+"/addoption", data);
  }

  deleteOptionList(id: any) {
    // return this._http.delete(`${this.url+"/deleteoption?id="}${id}`);
    return optimiseDeleteApi(`${this.url+"/deleteoption?id="}${id}`);
  }

  getList(id: any) {
    // return this._http.get(`${this.url + "/getoptionbyid?id="}${id}` + "&devicetype=website&email=");
    return optimiseEditApi(`${this.url+"/getoptionbyid?id="}${id}`+"&devicetype=website&email=");
  }

  updateOptionList(data: any) {
    // return this._http.put(`${this.url+"/updateoption"}`, data);
    return optimiseUpdateApi(`${this.url+"/updateoption"}`, data);
  }

  importExcel(data: any) {
    debugger
    return optimisePostApi(this.url+"/ImportExcelOption", data);
    // return this._http.post(this.url+"/ImportExcelOption", data);
  }

  // multiple delete method //
  deleteChekedList(id: any) {
    debugger
    // return this._http.delete(this.url + "/deletemultipleoptions?ids=" + id);
    return optimiseDeleteApi(this.url + "/deletemultipleoptions?ids=" + id);
  }

  exportToExcelOptionList(id: any) {
    debugger
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    return this._http.post(`${this.url + "/ExportExcelOption?QuizId="}${id}`, id, {
      responseType: 'arraybuffer'
    });

  }

}
