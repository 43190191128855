<p>{{errMsg}}</p>

<h1 class="text-center my-4 py-4">Topic List</h1>

<div class="row">
    <div class="col-lg-5 col-md-5 col-sm-4 mb-2">
        <button [routerLink]="['/home/Course']" mat-raised-button color="primary" id="topic">Back</button>
    </div>

    <div class="col-lg-3 col-md-2 col-sm-2">
        <span id="span" class=" text-muted fs-4">{{courseNameTitle}}</span>
    </div>

    <div class="col-lg-4 col-md-5 col-sm-6">
        <button (click)="clickToAddTopic()" mat-raised-button color="primary" id="course"><svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="rgba(255,255,255,1)" />
            </svg> Add Topic</button>
    </div>
</div>

<div>
    <mat-form-field class="w-25">
        <mat-label>Search here</mat-label>
        <input (keyup)="dataFilter($event.target)" matInput placeholder="Search...">
    </mat-form-field>

    <div class="mat-elevation-z8">
        <mat-table [dataSource]="dataSource" matSort>

            <!-- <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="courseID">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Course ID </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.courseID}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="image">
                <mat-header-cell *matHeaderCellDef > Image </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <!-- <img src="{{element.imageUrl}}" width="30px"/> -->
                  <img
                  src="https://englearn.sparkleweb.co.in/content/images/coursetopic/{{element.image}}" width="30px"> 
                  <!-- <img src="{{element.image}}"> -->
                  <!-- {{element.image}} -->
                </mat-cell>
              </ng-container>

            <!-- <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
            </ng-container>-->
            <ng-container matColumnDef="topicType">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Topic Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.topicType}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sortOrder">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Sort Order </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="chapter">
                <mat-header-cell *matHeaderCellDef> Chapter </mat-header-cell>
                <mat-cell *matCellDef="let element"> <a (click)="clickToChapterList(element)"><button mat-raised-button
                            color="primary" id="topic">Chapter List</button></a>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                <mat-cell *matCellDef="let element"> <a routerLink="/home/updateTopic/{{element.id}}"><i
                            class="fa fa-edit" style="font-size:25px;color:blue"></i></a> <span class="mx-1"></span><i
                        (click)="deleteRow(element.id)" class="fa fa-trash-o ml-2" style="font-size:25px;color:red"></i>
                </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSizeOptions]="pageSizeOptions"
            [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageIndex]="pageIndex-1">
        </mat-paginator>
        <!-- <mat-paginator [pageSize]="8" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons></mat-paginator> -->
    </div>


</div>

<!-- loading spinner  -->
<mat-card *ngIf="_loaderService.isLoading | async" style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</mat-card>