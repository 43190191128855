import { Injectable } from '@angular/core';
import { Model } from './model';

@Injectable({
  providedIn: 'root'
})
export class MyProductService {

  


constructor() { }

getProductList(){
  let products: Model[] = [];
  return products = [
    new Model(1,"product_1",500),
    new Model(2,"product_2",600),
    new Model(3,"product_3",800)
  ]; 
}

}
