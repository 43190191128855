<div id="div" class="container">
  <div class="row">
    <form id="form" [formGroup]="addCourseList" (ngSubmit)="onSubmit()">
      <h1 id="h1" class="my-4 py-3">
        {{ editMode ? "Edit" : "Add" }} Course
      </h1>

      <!-- {{editMode?'Update':'Add'}} -->

      <div class="d-lg-flex justify-content-around">
        <div>
          <mat-form-field appearance="fill" class="input-field">
            <mat-label>English Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="hasError('name', 'required')">Please enter English Course Name</mat-error>
          </mat-form-field>

          <br />

          <mat-label><b>Course Description :- </b></mat-label>
          <editor formControlName="shortDescription" class="input-field"
            style=" border-radius: 8px" apiKey="your-api-key" [init]="{
              base_url: '/tinymce',
              suffix: '.min',
              height: 150,
              menubar: true,
              content_style:
                'body { background: whitesmoke; color: black; font-family: verdana; }',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | styleselect | fontsizeselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | help'
            }"></editor>
            <mat-error *ngIf="hasError('shortDescription', 'required')">Note : This field is required</mat-error>

          <br />
          <mat-form-field appearance="fill" class="input-field">
            <mat-label> Hindi Name</mat-label>
            <input matInput formControlName="hindiName" />
            <mat-error *ngIf="hasError('hindiName', 'required')">Please enter Hindi Course Name</mat-error>
          </mat-form-field>

          <br />

          <mat-label><b>Hindi Course Description :-</b></mat-label>
          <editor formControlName="hindiShortDescription" class="input-field"
            style=" border-radius: 8px" apiKey="your-api-key" [init]="{
              base_url: '/tinymce',
              suffix: '.min',
              height: 150,
              menubar: true,
              content_style:
                'body { background: whitesmoke; color: black; font-family: verdana; }',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | styleselect | fontsizeselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | help'
            }"></editor>
            <mat-error *ngIf="hasError('hindiShortDescription', 'required')">Note : This field is required</mat-error>

          <br />
          <p>
            <mat-slide-toggle formControlName="isActive">Status</mat-slide-toggle>
            <span class="mx-3"></span>
            <!-- <mat-slide-toggle formControlName="isActive">Add Quiz</mat-slide-toggle> -->
          </p>
          <br />
        </div>
        <div class="mx-lg-3"></div>

        <div>
          <mat-form-field appearance="fill" class="input-field">
            <mat-label> Gujarati Name</mat-label>
            <input matInput formControlName="gujName" />
            <mat-error *ngIf="hasError('gujName', 'required')">Please enter Gujarati Course Name</mat-error>
          </mat-form-field>

          <br />

          <mat-label><b>Gujarati Course Description :- </b></mat-label>
          <editor formControlName="gujShortDescription" class="input-field"
            style=" border-radius: 8px" apiKey="your-api-key" [init]="{
              base_url: '/tinymce',
              suffix: '.min',
              height: 150,
              menubar: true,
              content_style:
                'body { background: whitesmoke; color: black; font-family: verdana; }',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | styleselect | fontsizeselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | help'
            }"></editor>
            <mat-error *ngIf="hasError('gujShortDescription', 'required')">Note : This field is required</mat-error>

          <!-- tinymce.init({
  selector: 'textarea#premiumskinsandicons-material-classic',
  skin: 'material-classic',
  content_css: 'material-classic',
  icons: 'material',
  plugins: 'code image link lists',
  toolbar: 'undo redo | styleselect | bold italic underline forecolor backcolor | link image code | align | bullist numlist',
  menubar: true
}); -->

          <br />

          <mat-form-field appearance="fill" class="input-field">
            <mat-label>Type</mat-label>
            <mat-select disableRipple formControlName="type">
              <mat-option value="Courses">Courses</mat-option>
              <mat-option value="Extra Learning">Extra Learning</mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('type', 'required')">Please select Course Type</mat-error>
          </mat-form-field>
          <br />

          <!-- <input type="file" class="input-field" formControlName="image" (change)="onImageSubmit($event)"/> -->
          <!-- <input type="file"  class="input-field" formControlName="image" (change)="onImageSubmit($event)"/>
    <h3>{{previousImg}}</h3>
      
    <img [src] = "previousImg" width="100px" height="100px" class="me-3" >
    <img [src] = "selectedFile" width="100px" height="100px">
    <h2>{{selectedFileName}}</h2> -->
          <mat-form-field appearance="fill" class="input-field">
            <mat-label>Sort Order</mat-label>
            <input matInput formControlName="sortOrder" />
            <mat-error *ngIf="hasError('sortOrder', 'required')">Sort Order is required</mat-error>
            <mat-error *ngIf="hasError('sortOrder', 'pattern')">Only numbers are allowed</mat-error>
          </mat-form-field>

          <br />

          <input type="file" class="input-field" #file formControlName="imageFile" (change)="onImageSubmit($event)" />

          <h2>{{ previousImg }}</h2>
          <p *ngIf="previousImg">Previous Image</p>
          <img *ngIf="previousImg" src="https://englearn.sparkleweb.co.in/content/images/course/{{
              previousImg
            }}" formControlName="image" width="100px" height="100px" />

          <!-- <img src="previousImg" width="100px"
            height="100px"> -->
          <span class="mx-2"></span>
          <img [src]="selectedFileShow" width="100px" formControlName="image" height="100px" />
          <!-- <img src="https://englearn.sparkleweb.co.in/content/images/Course/{{selectedFileName}}" formControlName="image" width="100px"
            height="100px"> -->
          <h2>{{ selectedFileName }}</h2>

          <!-- <p>
            <mat-slide-toggle formControlName="isActive">Status</mat-slide-toggle>
          </p> -->
        </div>
      </div>
      <button mat-raised-button color="primary" class="button1" type="submit">
        {{ editMode ? "Update" : "Submit" }}
      </button>
      <button mat-raised-button color="warn" class="mx-2" type="reset">
        Clear
      </button>
      <button mat-stroked-button color="primary" (click)="clicktobackList()">
        Cancel
      </button>

      <br /><br />
    </form>
  </div>
</div>

<!-- <button mat-raised-button color="primary">Submit</button> -->

<!-- loading spinner -->
<div class="spinner" *ngIf="_loaderService.isLoading | async"
  style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>