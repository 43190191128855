import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { disableDebugTools } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';
import Swal from 'sweetalert2';
import { MyUserService } from '../myUser.service';

@Component({
  selector: 'app-updateUser',
  templateUrl: './updateUser.component.html',
  styleUrls: ['./updateUser.component.css']
})
export class UpdateUserComponent implements OnInit {
 // isLoading= true;

  // public editMode:boolean = false;

  constructor(private _fb: FormBuilder, private _userService: MyUserService, private _activatedRoute: ActivatedRoute, private _router: Router, public _loaderService: LoaderService) { }

  userlist = this._fb.group({
    email: ['', [Validators.required]],
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    // password: ['', Validators.required],
    appLanguage: ['', [Validators.required]]
  });

  public error = (controlName: string, errorName: string) => {
    return this.userlist.controls[controlName].hasError(errorName);
  }

  ngOnInit(){
    this.getUserList();
  }

  getUserList(){
    this._userService.getList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {
      this.userlist = this._fb.group({
        id: [result.id],
        email: [result.email],
        firstname: [result.firstname, [Validators.required]],
        lastname: [result.lastname, [Validators.required]],
        phoneNumber: [result.phoneNumber, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        // password: [result.password, Validators.required],
        appLanguage: [result.appLanguage, Validators.required]

      });
      this.userlist.controls['email'].disable();
      // this.userlist.controls['password'].disable();

    });
  }

  onSubmit() {

    debugger
    console.log(this.userlist.value);
    if (this.userlist.invalid) {
      return
    }
    else{
      this.userlist.controls['id'].setValue(this._activatedRoute.snapshot.params.id);
      this._userService.updateUserList(this.userlist.value).subscribe((result: any) => {
        console.log(result);
        if (result.status == "success") {
          onSweetAlertSuccess(result);
          commonRouting('home/UserList');
          // this._router.navigate(['home/UserList']);
        } else if (result.status == "error") {
          onSweetAlertError(result);
        }
      });

    }
      
  }


}






