import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MyChapterService } from '../myChapter.service';
import Swal from 'sweetalert2';
import { LoaderService } from 'src/app/loader/loader.service';
// import { addition } from 'src/app/course/myCourse.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';


@Component({
  selector: 'app-chapter-list',
  templateUrl: './chapterList.component.html',
  styleUrls: ['./chapterList.component.css']
})
export class ChapterListComponent implements OnInit {

  displayedColumns: any[] = [ 'name', 'image', 'description', 'type', 'courseTopicID', 'sortOrder', 'quiz', 'action'];
  dataSource: any;
  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  // isLoading=true;
  public errMsg = "";

  public topicId: any;

  public courseId: any;

  public topicname: any;

  public chapterList2: any;

  constructor(private _route: Router, private _chapterService: MyChapterService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) { }

  totalRecords = 0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [8, 10, 25,50];
  showFirstLastButtons = true;
  // pagecount:any ="Selecte Page per Recode";
  // PageSizeCount(){
  //   this.pageSize=this.pagecount
  // }

  handlePageEvent(event: PageEvent) {
    debugger
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    // console.log(this.dataSource.paginator.pageIndex)
    // console.log(this.dataSource.paginator.length)
    // console.log(this.dataSource.paginator.pageSize)
    console.log(this.pageSize)
    console.log(this.pageIndex)
    this.getChapterList();


  }

  ngOnInit(): void {
    this.topicname = localStorage.getItem('TopicName');

    this.topicId = this._activatedRoute.snapshot.params.id;

this.getChapterList();
    // console.log(addition(10, 20));
  }

  dataFilter(filterValue: any) {
    this.dataSource.filter = filterValue.value.trim().toLowerCase();

    this._chapterService.getChapterList(this.topicId,this.pageSize, this.pageIndex , this.dataSource.filter).subscribe((result) => {
      // console.log(result, "1");
      this.dataSource = result;
      localStorage.setItem('TopicId', JSON.stringify(this.topicId));
      this.courseId = localStorage.getItem('CourseId');
      this.totalRecords = this.dataSource[0].totalRecords;
    //   // this.dataSource = new MatTableDataSource(this.dataSource);
    //   // this.dataSource.sort = this.sort;
    //   // this.dataSource.paginator = this.paginator;
    })
  }

  getChapterList(){
    this._chapterService.getChapterList(this.topicId,this.pageSize, this.pageIndex ,'').subscribe((result: any) => {
      console.log(result, "1");
      this.dataSource = result;
      localStorage.setItem('TopicId', JSON.stringify(this.topicId));
      this.courseId = localStorage.getItem('CourseId');
      this.totalRecords = this.dataSource[0].totalRecords;
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.dataSource.sort = this.sort;
      // this.dataSource.paginator = this.paginator;
    },
      error => {
        this.errMsg = error;
      })

  }

  clickToAddChapter() {
    commonRouting('home/ChapterAdd');
    // this._route.navigate(['/home/ChapterAdd']);
  }

  backToTopicList() {
    debugger
    this.courseId = localStorage.getItem('CourseId');
    let selectedId = JSON.parse(this.courseId);
    this._route.navigate(['/home/CourseTopic/', selectedId]);
  }

  

  deleteRow(item: any) {

    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._chapterService.deleteChapterList(item).subscribe((result: any) => {
          console.log(result, "delete");
          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });

  }

  clickToQuiz(obj: any) {
    localStorage.setItem('ChapterName', obj.name);
    this._route.navigate(['/home/quiz', obj.id])
  }

}
