import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.css']
})
export class ThemeComponent implements AfterViewInit,OnInit {

  constructor(private _eleRef:ElementRef) { }

  @ViewChild('name')
  elname!: ElementRef;
  @ViewChild('state') elstate!: ElementRef;

  ngAfterViewInit(){
    this.elname.nativeElement.style.backgroundColor="black";
    this.elname.nativeElement.style.color = "white";
    this.elstate.nativeElement.style.backgroundColor = "aqua";
    this.elstate.nativeElement.style.color = "red";
  }

  

  ngOnInit() {
  }

}
