<p>{{errMsg}}</p>

<h1 class="text-center  my-4 py-4">Chapter List</h1>

<div class="row">

    <div class="col-lg-5 col-md-4 col-sm-4">
        <a (click)="backToTopicList()"><button mat-raised-button color="primary" id="topic">Back</button></a>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-2">
        <span id="span" class="text-muted">{{topicname}}</span>
    </div>
    <div class="col-lg-4 col-md-5 col-sm-6 mb-2">
        <button (click)="clickToAddChapter()" mat-raised-button color="primary" id="chapter"><svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="rgba(255,255,255,1)" />
            </svg> Add Chapter</button>
    </div>

</div>


<div>

    <mat-form-field class="w-25">
        <mat-label>Search here</mat-label>
        <input (keyup)="dataFilter($event.target)" matInput placeholder="Search...">
    </mat-form-field>


    <div class="mat-elevation-z8">

        <mat-table [dataSource]="dataSource" matSort id="table">

            <!-- <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="courseTopicID">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Topic ID </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.courseTopicID}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Chapter Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="image">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Image </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.image}} </mat-cell>
            </ng-container> -->
            <ng-container matColumnDef="image">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Image </mat-header-cell>
                <mat-cell *matCellDef="let element"><img
                        src="https://englearn.sparkleweb.co.in/content/images/chapter/{{element.image}}" width="30px">
                    <!-- <img src="{{element.image}}"> -->
                    <!-- {{element.image}} -->
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="listType">
                <mat-header-cell *matHeaderCellDef mat-sort-header> List Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.listType}} </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="description" >
                <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                <mat-cell *matCellDef="let element" ><span [innerHTML]="element.description" class="description"></span> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sortOrder">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Sort Order </mat-header-cell>
                <mat-cell *matCellDef="let element"  > {{element.sortOrder}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="quiz">
                <mat-header-cell *matHeaderCellDef > Quiz List </mat-header-cell>
                <mat-cell *matCellDef="let element"><a (click)="clickToQuiz(element)"><button mat-raised-button
                            color="primary" id="topic">Start Quiz</button></a>

                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef > Action </mat-header-cell>
                <mat-cell *matCellDef="let element"> <a routerLink="/home/updateChapter/{{element.id}}"><i
                            class="fa fa-edit" style="font-size:25px;color:blue"></i></a> <span class="mx-1"></span><i
                        (click)="deleteRow(element.id)" class="fa fa-trash-o ml-2" style="font-size:25px;color:red"></i>
                </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <!-- <mat-paginator [pageSize]="8" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons></mat-paginator> -->
        <mat-paginator
        (page)="handlePageEvent($event)"
        [length]="totalRecords"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize"
        [showFirstLastButtons]="showFirstLastButtons"
        [pageIndex]="pageIndex-1"
      >
      </mat-paginator>
    </div>


</div>

<!-- loading spinner code -->
<mat-card *ngIf="_loaderService.isLoading | async" style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</mat-card>