import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyReplayService {

  constructor() { }

  data = new ReplaySubject(2);

}
