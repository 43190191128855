import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { CourseListComponent } from './course/course-list/course-list.component';
import { AddCourseComponent } from './course/add-course/add-course.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddTopicComponent } from './Topic/add-topic/add-topic.component';
import { TopicListComponent } from './Topic/topic-list/topic-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { ChapterListComponent } from './Chapter/chapterList/chapterList.component';
import { AddChapterComponent } from './Chapter/addChapter/addChapter.component';
import { DemoComponent } from './demo/demo.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QuizListComponent } from './Quiz/quiz-list/quiz-list.component';
import { AddQuizComponent } from './Quiz/add-quiz/add-quiz.component';
import { OptionListComponent } from './Option/option-list/option-list.component';
import { AddOptionComponent } from './Option/add-option/add-option.component';
import { ProductListComponent } from './product/product-list/product-list.component';
import { ProductDetailsComponent } from './product/product-details/product-details.component';
import { MyProductService } from './product/myProduct.service';
import { LoginComponent } from './accout/login/login.component';
import { ForgetComponent } from './accout/forget/forget.component';
import { EditorModule, TINYMCE_SCRIPT_SRC  } from '@tinymce/tinymce-angular';
import { SubjectComponent } from './subject/subject/subject.component';
import { BehaviourSubjectComponent } from './Behaviour-Subject/behaviour-subject/behaviour-subject.component';
import { ReplaySubjectComponent } from './Replay-Subject/replay-subject/replay-subject.component';
import { AsyncSubjectComponent } from './Async-Subject/async-subject/async-subject.component';
import { GuardModule } from './guard/guard.module';
import { PipeComponent } from './custom-pipe/pipe/pipe.component';
import { PipePipe } from './custom-pipe/pipe.pipe';
import { CounterComponent } from './ViewChild using component/counter/counter.component';
import { CounterParentComponent } from './ViewChild using component/counter-parent/counter-parent.component';
import { ParentColorComponent } from './ViewChild using directive/parent-color/parent-color.component';
import { ChangeColorDirective } from './ViewChild using directive/change-color.directive';
import { ThemeComponent } from './ViewChild using template/theme/theme.component';
import { MyCourseService } from './course/myCourse.service';
import { MyTopicService } from './Topic/myTopic.service';
import { MyChapterService } from './Chapter/myChapter.service';
import { MyQuizService } from './Quiz/myQuiz.service';
import { MyOptionService } from './Option/myOption.service';
import { UserComponent } from './user-list/user/user.component';
import { ListUserComponent } from './user-list/listUser/listUser.component';
import { UpdateUserComponent } from './user-list/updateUser/updateUser.component';
import { ForgotPasswordComponent } from './accout/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './accout/forgot-password/reset-password/reset-password.component';
import { ChangePasswordComponent } from './accout/change-password/change-password.component';
import { HomeComponent } from './HOME/home/home.component';
import { UpdateVersionComponent } from './Update-Version/updateVersion/updateVersion.component';
import { UpdateVersionListComponent } from './Update-Version/updateVersionList/updateVersionList.component';
import { MyUpdateVersionService } from './Update-Version/myUpdateVersion.service';
import { FeedbackComponent } from './FEEDBACK/feedback/feedback.component';
import { InterceptorService } from './loader/interceptor.service';
import {MatTooltipModule} from '@angular/material/tooltip'; 

export let AppInjector: Injector;

@NgModule({
  declarations: [
    AppComponent,
    CourseListComponent,
    AddCourseComponent,
    TopicListComponent,
    AddTopicComponent,
    AddChapterComponent,
    ChapterListComponent,
    HeaderComponent,
    DemoComponent,
    QuizListComponent,
    AddQuizComponent,
    OptionListComponent,
    AddOptionComponent,
    ProductListComponent,
    ProductDetailsComponent,
    LoginComponent,
    ForgetComponent,
    SubjectComponent,
    BehaviourSubjectComponent,
    ReplaySubjectComponent,
    AsyncSubjectComponent,
    PipeComponent,
    PipePipe,
    CounterComponent,
    CounterParentComponent,
    ParentColorComponent,
    ChangeColorDirective,
    ThemeComponent,
    UserComponent,
    ListUserComponent,
    UpdateUserComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    HomeComponent,
    FeedbackComponent,
    UpdateVersionComponent,
    UpdateVersionListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MaterialModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    EditorModule,
    GuardModule,
    MatTooltipModule
  

  ],
  providers: [MyProductService,{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },MyCourseService,MyTopicService,MyChapterService,MyQuizService,MyOptionService , MyUpdateVersionService,
  {provide:HTTP_INTERCEPTORS ,useClass:InterceptorService ,multi:true}],
  bootstrap: [AppComponent]
})

export class AppModule { 
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
