import { Component, OnInit } from '@angular/core';
import { Model } from '../model';
import { MyProductService } from '../myProduct.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  public product: Model[] = [];

  constructor(private _productService : MyProductService) { }

  ngOnInit() {
    this.product = this._productService.getProductList();
  }

}
