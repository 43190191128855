import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pipe',
  templateUrl: './pipe.component.html',
  styleUrls: ['./pipe.component.css']
})
export class PipeComponent implements OnInit {

  constructor() { }

  public user = [
    {id:1,name:"sita",gender:"female"},
    {id:2,name:"ram",gender:"male"},
    {id:3,name:"geeta",gender:"female"},
    {id:4,name:"laxman",gender:"male"},
    {id:5,name:"radha",gender:"female"}
    
  ];

  ngOnInit() {
  }

}
