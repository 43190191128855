import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MyCourseService } from '../myCourse.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.css']
})
export class CourseListComponent implements OnInit {
  displayedColumns: any[] = [ 'name', 'type', 'image', 'shortDescription', 'sortOrder', 'topic', 'action'];
  courselist: any;
  dataSource: any;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;



  public errMsg = "";
  public product_id: any;
  constructor(private _route: Router, private _courseService: MyCourseService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) { }

  totalRecords = 0;
  pageSize = 8;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25,50];
  showFirstLastButtons = true;
  // pagecount:any ="Selecte Page per Recode";
  // PageSizeCount(){
  //   this.pageSize=this.pagecount
  // }

  handlePageEvent(event: PageEvent) {
    debugger
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    // console.log(this.dataSource.paginator.pageIndex)
    // console.log(this.dataSource.paginator.length)
    // console.log(this.dataSource.paginator.pageSize)
    console.log(this.pageSize)
    console.log(this.pageIndex)
    this.getCourseList();

  }

  dataFilter(event: any) {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.courselist.filter = filterValue.trim().toLowerCase();
    this.courselist.filter = event.target.value.trim().toLowerCase();

    this._courseService.getCourseList(this.pageSize, this.pageIndex ,this.courselist.filter).subscribe((result) => {

      this.courselist = result;
      // console.warn(this.courselist[0].totalRecords);
      this.totalRecords = this.courselist[0].totalRecords;

    });
    
   }


  
  ngOnInit(): void {
    // debugger;
    this.getCourseList();
    
  }
  
 


  getCourseList() {

// debugger
    this._courseService.getCourseList(this.pageSize, this.pageIndex ,'').subscribe((result: any) => {

      console.log(result, "course");

      this.courselist = result;
      console.warn(this.courselist[0].totalRecords);
      this.totalRecords = this.courselist[0].totalRecords;
      this.courselist = new MatTableDataSource(this.courselist);
      this.courselist.sort = this.sort;
      // this.courselist.paginator = this.paginator;
    },
      error => {
        this.errMsg = error;
      });
  }

  clickToAddCourse() {
    commonRouting('home/CourseAdd');
  }

  
  deleteRow(item: any) {
    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._courseService.deleteCourseList(item).subscribe((result: any) => {
          console.log(result, "delete");
          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });

  }


  clickToTopicList(obj: any) {
    localStorage.setItem('CourseTitle', obj.name);
    localStorage.setItem('CourseId', obj.id);
    this._route.navigate(['/home/CourseTopic', obj.id]);

  }
}
