import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { MyReplayService } from '../myReplay.service';

@Component({
  selector: 'app-replay-subject',
  templateUrl: './replay-subject.component.html',
  styleUrls: ['./replay-subject.component.css']
})
export class ReplaySubjectComponent implements OnInit {

  data : number[] = [];
  
  constructor(private _replayService:MyReplayService) { 
    
  }

  ngOnInit() {
    this._replayService.data.next(500);
    this._replayService.data.next(50);
    this._replayService.data.next(20);
    this._replayService.data.subscribe((e:any) => this.data.push(e));
  }

}
