<form #myForm="ngForm" (ngSubmit) = "onSubmit(myForm)">
  <label>First Name:- </label>
  <input type="text" name="firstName" [(ngModel)] = "userModel.firstName"><br>
  <label>Last Name:- </label>
  <input type="text" name="lastName" [(ngModel)] = "userModel.lastName"><br>
  <button type="submit">Submit</button>
</form>

<!-- {{myForm.value | json}}
{{userModel| json}} -->
