import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangeColorDirective } from '../change-color.directive';

@Component({
  selector: 'app-parent-color',
  templateUrl: './parent-color.component.html',
  styleUrls: ['./parent-color.component.css']
})
export class ParentColorComponent implements OnInit {

  public color1 = "green";

  public color2 = "blue";

  public color3= "aqua";

  @ViewChild(ChangeColorDirective)
  change!: ChangeColorDirective;  

  changed(color:any){
    this.change.changeColor(color);
  }

  constructor() { }

  ngOnInit() {
  }

}
