import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { disableDebugTools } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertError, onSweetAlertError1, onSweetAlertSuccess } from 'src/app/optimiseCode';
import Swal from 'sweetalert2';
import { MyUserService } from '../myUser.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {


  // isLoading= true;

  // public editMode:boolean = false;
  constructor(private _fb: FormBuilder, private _userService: MyUserService, private _activatedRoute: ActivatedRoute, private _router: Router, public _loaderService: LoaderService) { }
  isLoading = true
  userlist = this._fb.group({
    email: ['', [Validators.required],[Validators.email]],
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    password: ['', Validators.required ], 
    appLanguage: ['', [Validators.required]]
  });

  public error = (controlName: string, errorName: string) => {
    return this.userlist.controls[controlName].hasError(errorName);
  }

  hide = true;
  // public editMode1:boolean = false;


  ngOnInit() {
    // this.getUserList();
    this.isLoading =false
  }

  // getUserList(){
  //   this._userService.getList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {
  //     this.editMode1 = true
  //     // this.editMode3 = this.editMode1;
  //     // this.isLoading =false;
  //     this.userlist = this._fb.group({
  //       id: [result.id],
  //       email: [result.email],
  //       firstname: [result.firstname, [Validators.required]],
  //       lastname: [result.lastname, [Validators.required]],
  //       phoneNumber: [result.phoneNumber, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
  //       password: [result.password, Validators.required],
  //       appLanguage: [result.appLanguage, Validators.required]

  //     });
  //     this.userlist.controls['email'].disable();
  //     this.userlist.controls['password'].disable();

  //   });
  // }

  onSubmit() {
  
    debugger
    console.log(this.userlist.value);
    if (this.userlist.invalid) {
      this.isLoading =false
      return 
    }
    else {
     
      debugger
      this._userService.postUserList(this.userlist.value).subscribe((result: any) => {
        console.log(result);
        this.isLoading =true
        if (result.status == "Success") {
          onSweetAlertSuccess(result);
          commonRouting('login')
          this.isLoading =false
          // this._router.navigate(['login']);
        }
      }, err => {
        console.log(err.error);
        if (err.error.status == "Error") {
          onSweetAlertError1(err);
          this.isLoading =false
        }
      }
      );
    }
    // if (this._activatedRoute.snapshot.params.id == "", this._activatedRoute.snapshot.params.id == "0", this._activatedRoute.snapshot.params.id == null) {
    //   this._userService.postUserList(this.userlist.value).subscribe((result: any) => {
    //     console.log(result);
    //     if (result.status == "Success") {
    //       onSweetAlertSuccess(result);
    //       commonRouting('login')
    //       // this._router.navigate(['login']);
    //     } else if (result.status == "error") {
    //       onSweetAlertError(result);
    //     }
    //   });
    // }

    // else {
    //   this.userlist.controls['id'].setValue(this._activatedRoute.snapshot.params.id);
    //   this._userService.updateUserList(this.userlist.value).subscribe((result: any) => {
    //     console.log(result);
    //     if (result.status == "success") {
    //       onSweetAlertSuccess(result);
    //       commonRouting('home/UserList');
    //       // this._router.navigate(['home/UserList']);
    //     } else if (result.status == "error") {
    //       onSweetAlertError(result);
    //     }
    //   });

    // }

  }


}





