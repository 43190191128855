import { Injectable } from '@angular/core';
import { AsyncSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyAsyncService {

constructor() { }

data = new AsyncSubject(); //only the last value send to subs when the execution completed

}
