import { Component, OnInit } from '@angular/core';
import { EmailValidator, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  hide = true;
  hide2 =true;
  hide3 =true;
  email:any
  token:any = []
  // changePasswordForm:FormGroup = new FormGroup({});
  
  constructor(private fb:FormBuilder ,private _myAccountService:MyAccountService ,private route:ActivatedRoute) { }
  changePasswordForm = this.fb.group({
    email :['', [Validators.required, Validators.email]],
    oldPassword:['',[Validators.required ,   Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'
    ),]],
    newPassword:['',[Validators.required ,   Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'
    ),]],
    confirmNewPssword:['',[Validators.required,]]},
  {
    validators:MustMatch('newPassword', 'confirmNewPssword')
  }

  )

  ngOnInit(): void {
   
    this.token= localStorage.getItem('token');
    console.log(this.token)
    let tokenConvert = JSON.parse(this.token)
    console.log(tokenConvert.email)
     this.email = tokenConvert.email
    console.log(this.email)
  }
  
  public error = (control:string, error:string) => {
    return this.changePasswordForm.controls[control].hasError(error);
  }

  postChangePassword(data:any)
  {
    
    if(this.changePasswordForm.invalid)
    {
      return 
      
    }
      else
      {
        this._myAccountService.postChangePassword(data).subscribe((res:any)=>{
          console.log(res);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: res.message,
        // showConfirmButton: false,
            confirmButtonText: 'OK',
            timer: 5000
            }).then((res)=>{
              if(res.isConfirmed)
              {
                location.reload()
              }
            })
            // this.changePasswordForm.reset();
        })
      
      }
    }

}
export function MustMatch(controlName: any, matchingControlName: any){
      
  {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
  
        if (matchingControl.errors && !matchingControl.errors.MustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        else if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ MustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
  }

}