import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from 'src/app/loader/loader.service';
import { MyUpdateVersionService } from '../myUpdateVersion.service';

@Component({
  selector: 'app-updateVersionList',
  templateUrl: './updateVersionList.component.html',
  styleUrls: ['./updateVersionList.component.css']
})
export class UpdateVersionListComponent implements OnInit {

  displayedColumns: any[] = [ 'appName', 'version', 'status', 'action'];
  updateVersionList1: any;
  // isLoading = true;
  
  // updateVersionList1: any = [{"id":1,"appName":"EngLearn","version":"1.0.0","status":true}];
  // @ViewChild(MatSort) sort: MatSort = new MatSort;
  // @ViewChild(MatPaginator)
  // paginator!: MatPaginator;

  constructor(private _updateVersion:MyUpdateVersionService ,public _loaderService:LoaderService) { }

  ngOnInit() {
    // this.updateVersionList1 = this._updateVersion.getUpdateVersionList();
    this._updateVersion.getUpdateVersionList().subscribe((result: any) => {
      console.log(result, "updateVersion");
      // this.isLoading = false;
      this.updateVersionList1 = result;
      localStorage.setItem('versionId',result[0].id);
      localStorage.setItem('versionAPPName',result[0].appName);
      localStorage.setItem('version',result[0].version);
      localStorage.setItem('versionStatus',result[0].status);
      // this.updateVersionList1 = new MatTableDataSource(this.updateVersionList1);
      // this.updateVersionList1.sort = this.sort;
      // this.updateVersionList1.paginator = this.paginator;
    })
  }

  // dataFilter(filterValue: any) {
  //   this.updateVersionList1.filter = filterValue.value.trim().toLowerCase();
  // }

}
