import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { optimiseGetApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyUpdateVersionService {

  constructor(private _http: HttpClient) { }

  public url = "https://englearn.sparkleweb.co.in/api/appversion";

  getUpdateVersionList() {
    // return this._http.get(this.url+"/getupdateversionbyid?id=1");
    return optimiseGetApi(this.url+"/getupdateversionbyid?id=1");
    // return [{"id":1,"appName":"EngLearn","version":"1.0.0","status":true}];
  }

  // getList(id: any) {
  //   return this._http.get(`${this.url + "/getupdatelist?Id="}${id}`);
  // }

  updateUpdateVersionList(data: any) {
    // return this._http.put(`${this.url+"/updateversion"}`, data);
    return optimiseUpdateApi(`${this.url+"/updateversion"}`, data);
  }
  

}
