import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';
import { MyUserService } from '../myUser.service';


@Component({
  selector: 'app-listUser',
  templateUrl: './listUser.component.html',
  styleUrls: ['./listUser.component.css']
})



export class ListUserComponent implements OnInit {

  displayedColumns: any[] = ['firstname', 'lastname', 'email', 'phoneNumber', 'appLanguage', 'action'];
  dataSource: any;

  appLanguage = new FormControl();
  appLanguageList: string[] = ['English', 'Hindi', 'Gujarati'];
  
  
  
  

  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  userList: any;

  constructor(private _userService: MyUserService, private _router: Router, public _loaderService: LoaderService) { }

  totalRecords = 0;
  pageSize = 8;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {
    debugger
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    // console.log(this.dataSource.paginator.pageIndex)
    // console.log(this.dataSource.paginator.length)
    // console.log(this.dataSource.paginator.pageSize)
    this.getUserList();

  }

  //search data function //
  dataFilter(event: any ) {
    this.dataSource.filter = event.target.value.trim().toLowerCase();
    
    // console.log(this.dataSource.filter)
    this._userService.getUserList(this.pageSize, this.pageIndex , this.dataSource.filter).subscribe((result) => {
      // console.log(result);
      this.dataSource = result;
      this.totalRecords = this.dataSource[0].totalRecords;
    
      // console.log(result)
    });
  
  }
  
  // language wise search //
  dropDownFilter(event: any) {
    debugger
 
    this.dataSource.filter = this.appLanguage;
    // this.dataSource.filter = event.target.value.trim().toLowerCase();

    this._userService.dropdownFilter(this.pageSize ,this.pageIndex, this.dataSource.filter).subscribe((res => {

      this.totalRecords = this.dataSource[0].totalRecords;
      console.log("appLang" + " " + res);
    })
    );

  }


  ngOnInit() {
    
    this.getUserList();
    

    
  }
  getUserList() {
    debugger
    //this._userService.getUserList(this.pageIndex , this.pageSize).subscribe((result) => {
    this._userService.getUserList(this.pageSize, this.pageIndex ,'').subscribe((result) => {
      console.log(result);
      this.dataSource = result;
      this.totalRecords = this.dataSource[0].totalRecords;
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.dataSource.sort = this.sort;
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.paginator.pageIndex = this.pageIndex;
      // this.dataSource.paginator.pageSize = this.pageSize;

      console.log(result)
    });
  
    
  }




  deleteRow(item: any) {
    debugger

    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._userService.deleteUserList(item).subscribe((result: any) => {
          console.log(result, "delete");
          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });
  }


  clickToAddUser() {
    commonRouting('home/AddUser');
  }


  // app laguage dropdown filter //

  
  // clickToRefresh() {
  //   window.location.reload()
  // }

}
// function search(pageSize: number, pageIndex: number, search: any) {
//   throw new Error('Function not implemented.');
// }

// function searchByLanguage(pageSize: number, pageIndex: number, filter: any, searchByLanguage: any) {
//   throw new Error('Function not implemented.');
// }


