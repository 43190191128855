
<div class="container">
    <div class="row">
      
      <div class="d-flex align-items-center justify-content-center w-100 ">
        <div class="my-3">
            <mat-card class="p-5"> 
              <h2 class="text-primary text-center ">Change Password</h2>
              <form [formGroup]="changePasswordForm" (ngSubmit)="postChangePassword(changePasswordForm.value)" class="py-1">
                <div class="">
                  
                    <div class="example-container" class="">
                      <mat-form-field style="width: 300px;" style="display: none;">
                        <mat-label>Enter your email</mat-label>
                        <input matInput placeholder="pat@example.com" formControlName="email" required [(ngModel)]="email" >
                        <mat-error *ngIf="error('email' ,'required')">Please enter Email id</mat-error>
                      </mat-form-field>
                    </div>
                    
          
                 </div>

            <div class="my-3">
                <mat-form-field style="width: 300px;" >
                    <mat-label>Enter  your old password</mat-label>
                   <input matInput [type]="hide ? 'password' : 'text'" class="w-100" formControlName="oldPassword" required>
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" type="button">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="error('oldPassword' ,'required')">Please enter old password</mat-error>
                    <mat-error *ngIf="error('newPassword' ,'pattern')"> Password must be 8 character and Mark@123</mat-error>
                    
                  </mat-form-field>  
        
               </div>
                  
               <div class="w-100 my-3">
                <mat-form-field style="width: 300px;">
                    <mat-label>Enter new password</mat-label>
                    <input matInput [type]="hide2 ? 'password' : 'text'" formControlName="newPassword" required>
                    <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2" type="button" >
                      <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="error('newPassword' ,'required')">Please enter new password</mat-error>
                    <mat-error *ngIf="error('newPassword' ,'pattern')">  Password must be 8 character and Mark@123</mat-error>

                  </mat-form-field>  
                  
               </div>  
        
                 <div class=" w-100">
                    <mat-form-field style="width: 300px;">
                        <mat-label>Confirm password</mat-label>
                        <input matInput [type]="hide3 ? 'password' : 'text'" formControlName="confirmNewPssword" required>
                        <button mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide3" type="button">
                          <mat-icon>{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="error('confirmNewPssword' ,'required')">Please enter confirm password</mat-error>
                        <!-- <mat-error *ngIf="error('newPassword' ,'pattern')">  Password must be 8 character and Mark@12345</mat-error> -->
                        <mat-error *ngIf="error('confirmNewPssword', 'MustMatch')">Password must match</mat-error>
                      
                      </mat-form-field>  
                    </div>
                    
                  
                        <button mat-flat-button color="primary" class="w-100 mt-3" type="submit">Change</button>
                      </form>
                </mat-card>
        </div>
    </div>
    </div>
</div>

