<div id="div" class="container">

    <form id="form" [formGroup]="addQuizList" (ngSubmit)="onSubmit()">

        <h1 id="h1" class="text-center my-3 py-4 "> {{editMode?'Edit':'Add'}} Quiz</h1>

        <!-- <h2 class="text-muted">Chapter Id :- {{CHAPTER_ID2}}</h2> -->

        <br><br>

        <mat-form-field appearance="fill" class="input-field">
            <mat-label>Question</mat-label>
            <input matInput formControlName="question">
            <mat-error *ngIf="hasError('question', 'required')"> Please enter Question </mat-error>
        </mat-form-field>



        <mat-form-field appearance="fill" class="input-field" hidden>
            <mat-label>Chapter Id</mat-label>
            <input matInput formControlName="chapterID">
            <mat-error *ngIf="hasError('chapterID', 'required')">Please enter Chapter Id</mat-error>
        </mat-form-field>

        <br>

        <mat-form-field appearance="fill" class="input-field">
            <mat-label>SortOrder</mat-label>
            <input matInput formControlName="sortOrder">
            <mat-error *ngIf="hasError('sortOrder', 'required')">SortOrder is required</mat-error>
            <mat-error *ngIf="hasError('sortOrder', 'pattern')">Only numbers are allowed</mat-error>
        </mat-form-field>

        <br>
        
        <mat-form-field appearance="fill" class="input-field">
            <mat-label> Hindi Question</mat-label>
            <input matInput formControlName="hindiQuestion">
            <mat-error *ngIf="hasError('hindiQuestion', 'required')">Please enter Hindi Question </mat-error>
        </mat-form-field>

        <br>
        <br>

        <mat-form-field appearance="fill" class="input-field">
            <mat-label> Gujarati Question</mat-label>
            <input matInput formControlName="gujQuestion">
            <mat-error *ngIf="hasError('gujQuestion', 'required')">Please enter Gujarati Question</mat-error>
        </mat-form-field>
        <br>

        <!-- <input type="file" class="input-field"   formControlName="image" (change)="onImageSubmit($event)" />
        <h2>{{previousImg}}</h2>

        <img [src]="previousImg" width="100px" height="100px">
        <span class="mx-2"></span>
        <img [src]="selectedFile" width="100px" height="100px">
        <h2>{{selectedFileName}}</h2> -->
        <input type="file" class="input-field" formControlName="imageFile" (change)="onImageSubmit($event)" />
        <h2>{{previousImg}}</h2>
        <p *ngIf="previousImg">Previous Image</p>
        <img *ngIf="previousImg" src="https://englearn.sparkleweb.co.in/content/images/quiz/{{previousImg}}" formControlName="image" width="100px"
        height="100px">
        <!-- <img [src]="previousImg" width="100px" height="100px"> -->
        <span class="mx-2"></span>
        <img [src]="selectedFileShow" formControlName="image" width="100px" height="100px">
        <h2>{{selectedFileName}}</h2>

        <p>
            <!-- <mat-checkbox formControlName="isActive">Status</mat-checkbox> -->
            <mat-slide-toggle formControlName="isActive">Status</mat-slide-toggle>
        </p>

     
        <br>
        <button mat-raised-button color="primary" type="submit">{{editMode?'Update':'Submit'}}</button>
        <button mat-raised-button color="warn" class="mx-2" type="reset">Clear</button>
        <button mat-stroked-button color="primary" (click)="clicktobackList()">Cancel</button>

    </form>

</div>

 <!-- loading spinner -->
 <div class="spinner" *ngIf="_loaderService.isLoading | async" style="display: flex; justify-content: center; align-items: center ; ">
    <mat-progress-spinner 
      color="primary" 
      mode="indeterminate">
    </mat-progress-spinner>
  </div>