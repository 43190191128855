import { Component, OnInit } from '@angular/core';
import { Model } from '../model';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css']
})
export class DemoComponent implements OnInit {

  public userModel = new Model("","");

  constructor() { }

  ngOnInit() {
  }

  onSubmit(data:any){
    console.log(data.value);
  }

}
