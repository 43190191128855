<div class="pt-5"> 
    <mat-card class="w-50 mx-auto p-5" >
        <h3 class="text-primary" >Forgot Password?</h3>
       <form [formGroup]="forgotPasswordForm" (ngSubmit)="postForgotPassword()">
    
          <div class="example-container">
            <mat-form-field class="w-75 p-1">
              <mat-label>Enter your email</mat-label>
              <input matInput placeholder="pat@example.com" formControlName="email" required>
              <mat-error *ngIf = "error('email','pattern')">Please enter valid Email ID </mat-error>
              <mat-error *ngIf="error('email' ,'required')">Please enter your Email ID</mat-error>
            </mat-form-field>
          </div>

          <button mat-raised-button color="primary" type="submit" class="my-2 w-25">Send</button>
        </form> 
    </mat-card>
</div>

