<h1 id="h1" class="text-center ">Welcome to English Learn App</h1>



<div id="div" class="" style="padding-top:10%;" >
  <mat-card class="card mx-auto">
    
      <mat-card-title >
        <h1  id="h" class="text-center">Login</h1>
      </mat-card-title>
    
    <mat-card-content id="content" >
      <form [formGroup] = "login" id="form" class="my-5 " novalidate>
        <mat-form-field class="input-field" class="w-100">
          <mat-label>Email Id</mat-label>
          <input type="email" matInput formControlName = "email"  required>
          <mat-error *ngIf = "error('email','required')">Email is required</mat-error>
        </mat-form-field>
        <br><br>
        <mat-form-field class="input-field" class="w-100">
          <mat-label>Password</mat-label>
          <input  matInput [type]="hide ? 'password' : 'text'" formControlName = "password" required>
          <button  mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" type="button" >
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="error('password','required')">Password is required</mat-error>
        </mat-form-field>

        <br><br><br>
        <div class="w-100">
          
        <button mat-raised-button color="primary" type="submit" (click)="onSubmit()" class="w-100">LogIn</button><br>
        <div class="my-2"></div>
        <!-- <button mat-stroked-button color="primary" class=" " type="button" (click)="forgotPasswordNavigate()" class="w-100" >Forgot Password?</button> -->
        
        <button mat-stroked-button color="primary" type="submit" (click)="signUp()" class="w-100">Sign Up</button><br>
        <div class="my-2"></div>
        <a (click)="forgotPasswordNavigate()" class=" float-end my-2  materialColor " >Forgot Password?</a>
       
      </div>
       

      </form>
    </mat-card-content>

    <div class="alert alert-danger" role="alert" *ngIf="errorMsgHide">
      <span *ngIf="errorMsg" class="">{{msg}}</span>
    </div>
  </mat-card>
    

  
</div>
