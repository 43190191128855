import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { optimiseDeleteApi, optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyQuizService {

  public url = "https://englearn.sparkleweb.co.in/api/quiz";

  constructor(private _http: HttpClient) { }

  getQuizList(id: any,pageSize:number,pageIndex:number,search:any) {
    // return this._http.get(this.url + "/getquizbychapterid?ChapterId=" + id + "&devicetype=website").pipe(catchError(this.errorHandler));
    return optimiseGetApi(`${this.url+"/getquizbychapterid?PageIndex="}${pageIndex}&PageSize=${pageSize}&Search=${search}&ChapterId=${id}`).pipe(catchError(this.errorHandler));
    // return optimiseGetApi(`${this.url+"/getquizbychapterid?PageIndex="}${pageIndex}&PageSize=${pageSize}&ChapterId=${id}`);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Unknown server error");
  }

  postQuizList(data: any) {
    debugger;
    // return this._http.post(this.url + "/addquiz", data);
    return optimisePostApi(this.url+"/addquiz", data);
  }

  deleteQuizList(id: any) {
    // return this._http.delete(`${this.url + "/deletequiz?id="}${id}`);
    return optimiseDeleteApi(`${this.url + "/deletequiz?id="}${id}`);
  }

  getList(id: any) {
    debugger
    // return this._http.get(`${this.url + "/getquizbyid?id="}${id}`+"&devicetype=website&email=");
    return optimiseEditApi(`${this.url + "/getquizbyid?id="}${id}` + "&devicetype=website&email=");
  }

  updateQuizList(formData: any) {
    //formData.append('ImageFile', file);
    // return this._http.put(`${this.url + "/updatequiz"}`, formData);
    return optimiseUpdateApi(`${this.url + "/updatequiz"}`, formData);
  }
}
