import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { commonRouting } from 'src/app/optimiseCode';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hide = true;
  constructor(private _fb: FormBuilder, private _accountService: MyAccountService, private _router: Router) { }

  login = this._fb.group({
    email: ['', [Validators.required],[Validators.email]],
    password: ['', [Validators.required]]
  });

  public error = (control: string, error: string) => {
    return this.login.controls[control].hasError(error);
  }

  public userData: any;

  public getToken: any;

  public errorMsg = false;

  public errMsg = "Please Enter Valid Email and Password";

  public msg = "";

  errorMsgHide = false;
  

  ngOnInit() {
    if (localStorage.getItem('token') != null) {
      commonRouting('home/UserList');
      // this._router.navigate(['home/UserList']);
    }
  }

  onSubmit() {
    if (this.login.invalid) {
      

      return ;
    }

    else {

      this._accountService.postLoginList(this.login.value).subscribe((result: any) => {
        console.log(result);
        localStorage.setItem('token', JSON.stringify(result));
        commonRouting('home/dashboard');
      },
        error => {
          if (error.status == 401) {
            this.errorMsgHide = true;
            this.errorMsg = true;
            this.msg = this.errMsg;
            console.log("Please Enter Valid Email and Password");
          }
        });
    }
  }
  
  forgotPasswordNavigate() {
    commonRouting('forgotpassword');
    // this._router.navigate(['forgotpassword']);
  }

  signUp() {
    // commonRouting('AddUser');
    this._router.navigate(['signUp']);
  }

}
