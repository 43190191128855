import { Component, OnInit } from '@angular/core';
import { MyAsyncService } from '../myAsync.service';

@Component({
  selector: 'app-async-subject',
  templateUrl: './async-subject.component.html',
  styleUrls: ['./async-subject.component.css']
})
export class AsyncSubjectComponent implements OnInit {

  public result : any;
  constructor(private _asyncService : MyAsyncService) { }

  ngOnInit() {
    
    this._asyncService.data.next(14);
    this._asyncService.data.subscribe(e => {
      this.result = e;
    });
    this._asyncService.data.next(3);
    this._asyncService.data.next(48);
    this._asyncService.data.next(88); //last value will be sent to its subs
    this._asyncService.data.complete();
  }

}
