import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';
import { MyOptionService } from '../myOption.service';

@Component({
  selector: 'app-add-option',
  templateUrl: './add-option.component.html',
  styleUrls: ['./add-option.component.css']
})
export class AddOptionComponent implements OnInit {
  // isLoading= false;
  propertyName = "testing";
  storeSortOrder: any;

  getSortOrder: any;

  parseSortOrder: any;

  autoIncreaseSortOrder: any;

  storeAutoIncreaseSortOrder: any;

  getAutoIncreaseSortOrder: any = localStorage.getItem('OptionAutoIncrease');

  public editMode:boolean = false;

  constructor(private _fb: FormBuilder, private _optionService: MyOptionService, private _router: Router, private _activatedRoute: ActivatedRoute ,public _loaderService:LoaderService) { }

  quizId:any = localStorage.getItem('QuizId');

  public QUIZ_ID = JSON.parse(this.quizId);

  addOption = this._fb.group({
    text: ['', [Validators.required]],
    correctAnswer: [false, [Validators.required]],
    quizID: [this.QUIZ_ID, [Validators.required]],
    hindiText: ['', [Validators.required]],
    gujText: ['', [Validators.required]],
    sortOrder:[(localStorage.getItem('OptionSortOrder') == null ? 1 :this.getAutoIncreaseSortOrder), [Validators.required, Validators.pattern(/^[0-9]\d*$/)]]
  });

  public hasError = (control: string, error: string) => {
    return this.addOption.controls[control].hasError(error);
  }

  ngOnInit() {
    this._optionService.getList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {
      this.editMode = true;
      this.addOption = this._fb.group({
        id: [result.id],
        text: [result.text, [Validators.required]],
        correctAnswer: [result.correctAnswer, [Validators.required]],
        quizID: [result.quizID, [Validators.required]],
        hindiText: [result.hindiText, [Validators.required]],
        gujText: [result.gujText, [Validators.required]],
        sortOrder:[result.sortOrder, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]]
      });
    });
  }

  onSubmit() {
    if (this.addOption.invalid) {
      return;
    }
    debugger
    if (this._activatedRoute.snapshot.params.id == '', this._activatedRoute.snapshot.params.id == "0", this._activatedRoute.snapshot.params.id == null) {
      this._optionService.postOptionList(this.addOption.value).subscribe((result: any) => {
        this.storeSortOrder = localStorage.setItem('OptionSortOrder', this.addOption.value.sortOrder);
        this.getSortOrder = localStorage.getItem('OptionSortOrder');
        this.parseSortOrder = JSON.parse(this.getSortOrder);
        console.log(this.parseSortOrder);
        this.autoIncreaseSortOrder = this.parseSortOrder + 1;
        console.log(this.autoIncreaseSortOrder);
        this.storeAutoIncreaseSortOrder = localStorage.setItem('OptionAutoIncrease', this.autoIncreaseSortOrder);
        if (result.status == "success") {
          onSweetAlertSuccess(result);
          this._router.navigate(['/home/option', JSON.parse(this.quizId)]);
        } else if (result.status == "Already Exist") {
          onSweetAlertError(result);
        }
      });
    }
    else {
      this.addOption.controls['id'].setValue(this._activatedRoute.snapshot.params.id);
      this._optionService.updateOptionList(this.addOption.value).subscribe((result: any) => {
        if (result.status == "success") {
          onSweetAlertSuccess(result);
          this._router.navigate(['/home/option', JSON.parse(this.quizId)]);
        }
      });
    }

  }


  clicktobackList() {
    this._router.navigate(['/home/option', JSON.parse(this.quizId)]);
  }

}
