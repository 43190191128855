import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddChapterComponent } from './Chapter/addChapter/addChapter.component';
import { AddCourseComponent } from './course/add-course/add-course.component';
import { AddTopicComponent } from './Topic/add-topic/add-topic.component';
import { ChapterListComponent } from './Chapter/chapterList/chapterList.component';
import { CourseListComponent } from './course/course-list/course-list.component';
import { TopicListComponent } from './Topic/topic-list/topic-list.component';
import { DemoComponent } from './demo/demo.component';
import { QuizListComponent } from './Quiz/quiz-list/quiz-list.component';
import { AddQuizComponent } from './Quiz/add-quiz/add-quiz.component';
import { OptionListComponent } from './Option/option-list/option-list.component';
import { AddOptionComponent } from './Option/add-option/add-option.component';
import { ProductListComponent } from './product/product-list/product-list.component';
import { ProductDetailsComponent } from './product/product-details/product-details.component';
import { LoginComponent } from './accout/login/login.component';
import { SubjectComponent } from './subject/subject/subject.component';
import { BehaviourSubjectComponent } from './Behaviour-Subject/behaviour-subject/behaviour-subject.component';
import { ReplaySubjectComponent } from './Replay-Subject/replay-subject/replay-subject.component';
import { AsyncSubjectComponent } from './Async-Subject/async-subject/async-subject.component';
import { AuthGuard } from './guard/auth.guard';
import { PipeComponent } from './custom-pipe/pipe/pipe.component';
import { CounterParentComponent } from './ViewChild using component/counter-parent/counter-parent.component';
import { ParentColorComponent } from './ViewChild using directive/parent-color/parent-color.component';
import { ThemeComponent } from './ViewChild using template/theme/theme.component';
import { UserComponent } from './user-list/user/user.component';
import { ListUserComponent } from './user-list/listUser/listUser.component';
import { ActivateGuard } from './authentication/activate.guard';
import { HeaderComponent } from './header/header.component';
import { ForgotPasswordComponent } from './accout/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './accout/change-password/change-password.component';
import { HomeComponent } from './HOME/home/home.component';
import { FeedbackComponent } from './FEEDBACK/feedback/feedback.component';
import { UpdateVersionListComponent } from './Update-Version/updateVersionList/updateVersionList.component';
import { UpdateVersionComponent } from './Update-Version/updateVersion/updateVersion.component';
import { UpdateUserComponent } from './user-list/updateUser/updateUser.component';

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  // {path:"home",component:HeaderComponent, canActivate:[ActivateGuard], children:[
  //   {path:"demo", component:DemoComponent},
  {
    path: "home", component: HeaderComponent, canActivate: [ActivateGuard], children: [
      { path: "dashboard", component: HomeComponent },
      { path: "Course", component: CourseListComponent },
      { path: "CourseAdd", component: AddCourseComponent },
      { path: "Course/:id", component: AddCourseComponent },
      { path: "CourseTopic", component: TopicListComponent },
      { path: "CourseTopic/:id", component: TopicListComponent },
      { path: "CourseTopicAdd", component: AddTopicComponent },
      { path: "Chapter/:id", component: ChapterListComponent },
      { path: "ChapterAdd", component: AddChapterComponent },
      { path: "updateTopic/:id", component: AddTopicComponent },
      { path: "updateChapter/:id", component: AddChapterComponent },
      { path: "quiz", component: QuizListComponent },
      { path: "quiz/:id", component: QuizListComponent },
      { path: "QuizAdd", component: AddQuizComponent },
      { path: "updateQuiz/:id", component: AddQuizComponent },
      { path: "option", component: OptionListComponent },
      { path: "option/:id", component: OptionListComponent },
      { path: "OptionAdd", component: AddOptionComponent },
      { path: "updateOption/:id", component: AddOptionComponent },
      { path: "AddUser", component: UserComponent },
      { path: "UserList", component: ListUserComponent },
      { path: "updateUser/:id", component: UpdateUserComponent },
      { path: "changepassword", component: ChangePasswordComponent },
      { path: "feedBack", component: FeedbackComponent },
      { path: "updateVersion", component: UpdateVersionListComponent },
      { path: "updatedVersion/:id", component: UpdateVersionComponent }
    ]
  },

  
  // { path: "updateUser/:id", component: UserComponent },
  { path: "product-list", component: ProductListComponent },
  { path: "product-list/:id", component: ProductDetailsComponent },
  { path: "subject", component: SubjectComponent },
  { path: "behaviour-subject", component: BehaviourSubjectComponent },
  { path: "replay-subject", component: ReplaySubjectComponent },
  { path: "async-subject", component: AsyncSubjectComponent },
  { path: 'guard', loadChildren: () => import('./guard/guard.module').then(m => m.GuardModule), canActivate: [AuthGuard] },
  { path: "custom-pipe", component: PipeComponent },
  { path: "counter-parent", component: CounterParentComponent },
  { path: "parent-color", component: ParentColorComponent },
  { path: "theme", component: ThemeComponent },
  { path: "forgotpassword", component: ForgotPasswordComponent },
  { path: "signUp", component: UserComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
