import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { optimiseDeleteApi, optimiseGetApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyFeedbackService {

  constructor(private _http: HttpClient) { }

  public url = "https://englearn.sparkleweb.co.in/api/feedback";

  getFeedBackList() {
    // return this._http.get(this.url+"/getfeedbacklist?devicetype=website");
    return optimiseGetApi(this.url+"/getfeedbacklist?devicetype=website");
  }

  deleteFeedBackList(id:any){
    debugger
    // return this._http.delete(`${this.url+"/deletefeedback?Id="}${id}`);
    return optimiseDeleteApi(`${this.url+"/deletefeedback?Id="}${id}`);
  }


}
