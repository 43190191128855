<div id="div" class="container">
  <div class="row">

    <form id="form" [formGroup]="addChapterList" (ngSubmit)="onSubmit()">

      <h1 id="h1" class="text-center my-3 py-3">{{editMode?'Edit':'Add'}} Chapter</h1>

      <!-- <h2 class="text-center text-muted">Course Topic Id :- {{TOPIC_ID2}}</h2> -->
      <div class="">
        <div class="d-lg-flex justify-content-center">
          <div>


            <mat-form-field appearance="fill" class="input-field">
              <mat-label>Chapter Name</mat-label>
              <input matInput formControlName="name">
              <mat-error *ngIf="hasError('name', 'required')">Please enter Chapter Name</mat-error>
            </mat-form-field><br>


            <mat-label><b>Chapter Description :- </b></mat-label>
            <editor formControlName="description" class="input-field" apiKey="your-api-key" [init]="{
      base_url: '/tinymce', 
suffix: '.min',    
height: 150,
menubar: true,
content_style:
'body { background: whitesmoke; color: black; font-family: verdana; }',
plugins: [
 'advlist autolink lists link image charmap print preview anchor',
 'searchreplace visualblocks code fullscreen',
 'insertdatetime media table paste code help wordcount'
],
toolbar:
 'undo redo | formatselect | bold italic backcolor | \
 alignleft aligncenter alignright alignjustify | \
 bullist numlist outdent indent | removeformat | help'
}"></editor>
<mat-error *ngIf="hasError('description', 'required')">Note : This field is required</mat-error>
            <br>


            <mat-form-field appearance="fill" class="input-field">
              <mat-label>Hindi Chapter Name</mat-label>
              <input matInput formControlName="hindiName">
              <mat-error *ngIf="hasError('hindiName', 'required')">Please enter Hindi Chapter Name</mat-error>
            </mat-form-field><br>

            <mat-label><b>Hindi Chapter Description :- </b></mat-label>
            <editor formControlName="hindiDescription" class="input-field" apiKey="your-api-key" [init]="{
            base_url: '/tinymce', 
          suffix: '.min',    
          height: 150,
          menubar: true,
          content_style:
          'body { background: whitesmoke; color: black; font-family: verdana; }',
          plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
          'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help'
          }"></editor>
          <mat-error *ngIf="hasError('hindiDescription', 'required')">Note : This field is required</mat-error>
          <br>

          <p>
            <mat-slide-toggle formControlName="isActive">Status</mat-slide-toggle>
            <span class="ms-5"></span>
            <mat-slide-toggle formControlName="isQuiz">Show Quiz</mat-slide-toggle>
          </p>
          </div>

          <div>

            <mat-form-field appearance="fill" class="input-field" hidden>
              <mat-label>Topic Id </mat-label>
              <input matInput formControlName="courseTopicID">
              <mat-error *ngIf="hasError('courseTopicID', 'required')"> Please enter Topic Id </mat-error>
            </mat-form-field><br>

           

          </div>

          <div class="mx-lg-3">

         
          </div>

          <div>
            <mat-form-field appearance="fill" class="input-field">
              <mat-label>Gujarati Chapter Name</mat-label>
              <input matInput formControlName="gujName">
              <mat-error *ngIf="hasError('gujName', 'required')">Please enter Gujarati Chapter Name</mat-error>
            </mat-form-field><br>

            <mat-label><b>Gujarati Chapter Description :- </b></mat-label>
            <editor formControlName="gujDescription" class="input-field" apiKey="your-api-key" [init]="{
  base_url: '/tinymce', 
suffix: '.min',    
height: 150,
menubar: true,
content_style:
'body { background: whitesmoke; color: black; font-family: verdana; }',
plugins: [
'advlist autolink lists link image charmap print preview anchor',
'searchreplace visualblocks code fullscreen',
'insertdatetime media table paste code help wordcount'
],
toolbar:
'undo redo | formatselect | bold italic backcolor | \
alignleft aligncenter alignright alignjustify | \
bullist numlist outdent indent | removeformat | help'
}"></editor>
<mat-error *ngIf="hasError('gujDescription', 'required')">Note : This field is required</mat-error>
<br>

            <mat-form-field appearance="fill" class="input-field">
              <mat-label>Type</mat-label>
              <mat-select disableRipple formControlName="type">
                <mat-option value="List">List</mat-option>
                <mat-option value="Tiles">Tiles</mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('type', 'required')">Please select Chapter Type</mat-error>
            </mat-form-field><br>

            <!-- <mat-form-field appearance="fill" class="input-field">
              <mat-label>List Type</mat-label>
              <input matInput formControlName="listType">
              <mat-error *ngIf="hasError('listType', 'required')">List Type is required</mat-error>
            </mat-form-field>
            <br> -->
            <!-- <input class="ng-hide" id="input-file-id" multiple type="file" accept="image/*" class="input-field"
formControlName="image" /> -->

            <mat-form-field appearance="fill" class="input-field">
              <mat-label>SortOrder</mat-label>
              <input matInput formControlName="sortOrder">
              <mat-error *ngIf="hasError('sortOrder', 'required')">SortOrder is required</mat-error>
              <mat-error *ngIf="hasError('sortOrder', 'pattern')">Only numbers are allowed</mat-error>
            </mat-form-field>

            <br>

            <input type="file" class="input-field" formControlName="imageFile" (change)="onImageSubmit($event)" />
            <h2>{{previousImg}}</h2>
            <p *ngIf="previousImg">Previous Image</p>
            <img *ngIf="previousImg" src="https://englearn.sparkleweb.co.in/content/images/chapter/{{previousImg}}" formControlName="image"
              width="100px" height="100px">
            <!-- <img [src]="previousImg" width="100px" height="100px"> -->
            <span class="mx-2"></span>
            <img [src]="selectedFileShow" formControlName="image" width="100px" height="100px">
            <h2>{{selectedFileName}}</h2>

            <!-- <p>
              <mat-slide-toggle formControlName="isActive">Status</mat-slide-toggle>
            </p> -->
          </div>

        </div>
        <div class="" style="margin-left:170px ;">
          <button mat-raised-button color="primary" class="button1"
            type="submit">{{editMode?'Update':'Submit'}}</button>
          <button mat-raised-button color="warn" class="mx-2" type="reset">Clear</button>
          <button mat-stroked-button color="primary" (click)="clicktobackList()">Cancel</button>
        </div>

      </div>
    </form>
  </div>
</div>

<!-- loading spinner -->
<div class="spinner" *ngIf="_loaderService.isLoading | async"
  style="display: flex; justify-content: center; align-items: center ; ">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>