<div id="div" class="mt-4 pt-4">

  <form id="form" [formGroup]="userlist">

    <h1 id="h1" class="mt-2">Update User</h1>
    <!-- <h1 id="h1" class="mt-2" *ngIf="editMode">{{editMode3}} User</h1> -->

    <mat-form-field appearance="fill" class="input-field">
      <mat-label>Email ID</mat-label>
      <input matInput formControlName="email" pattern="^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
      <mat-error *ngIf="error('email','required')">Email is required</mat-error>
      <mat-error *ngIf="error('email','pattern')">Please Enter Valid Email Id</mat-error>
    </mat-form-field><br><br>

    <mat-form-field appearance="fill" class="input-field">
      <mat-label>First Name</mat-label>
      <input matInput formControlName="firstname">
      <mat-error *ngIf="error('firstname','required')">First Name is required</mat-error>
    </mat-form-field><br><br>

    <mat-form-field appearance="fill" class="input-field">
      <mat-label>Last Name</mat-label>
      <input matInput formControlName="lastname">
      <mat-error *ngIf="error('lastname','required')">Last Name is required</mat-error>
    </mat-form-field><br><br>

    <mat-form-field appearance="fill" class="input-field">
      <mat-label>Phone Number</mat-label>
      <input matInput formControlName="phoneNumber">
      <mat-error *ngIf="error('phoneNumber','required')">Phone Number is required</mat-error>
      <mat-error *ngIf="error('phoneNumber','pattern')">Please Enter 10 digits only</mat-error>
    </mat-form-field><br><br>

    <!-- select language -->
    <mat-form-field appearance="fill" class="input-field">
      <mat-label>Select Language</mat-label>
      <mat-select disableRipple formControlName="appLanguage">
        <mat-option value="English">English</mat-option>
        <mat-option value="Hindi">Hindi</mat-option>
        <mat-option value="Gujarati">Gujarati</mat-option>
      </mat-select>
      <mat-error *ngIf="error('appLanguage','required')">app Language is required</mat-error>
    </mat-form-field>
    <br><br>

    <!-- <mat-form-field appearance="fill" class="input-field">
      <mat-label>Password</mat-label>
      <input matInput formControlName="password" [type]="hide ? 'password' : 'text'"
        pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}">
      <button mat-icon-button color="primary" matSuffix (click)="hide = !hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error *ngIf="error('password','required')">Password is required</mat-error>
      <mat-error *ngIf="error('password','pattern')">Password should have Minimum 8 Characters and atleast 1 uppercase 1
        lowercase 1 special character 1 number.</mat-error>
    </mat-form-field><br><br> -->

    <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">Submit</button>
    <button mat-raised-button color="warn" type="reset" class="mx-1">Clear</button>
    <button mat-stroked-button color="primary" [routerLink]="['/home/UserList']">Cancel</button>

  </form>


</div>
<!-- loading spinner -->
<div class="spinner" *ngIf="_loaderService.isLoading | async">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>