import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appChangeColor]'
})
export class ChangeColorDirective implements AfterViewInit{

  constructor(private _eleRef:ElementRef) { }

  ngAfterViewInit(){
    this._eleRef.nativeElement.style.color="red";
  }


  changeColor(changedColor:any){
    this._eleRef.nativeElement.style.color = changedColor;
  }

}
