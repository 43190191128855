<p style="margin-top: 50px">{{ errMsg }}</p>

<h1 class="my-3 py-2">Course List</h1>

<button
  (click)="clickToAddCourse()"
  mat-raised-button
  color="primary"
  id="course"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
      fill="rgba(255,255,255,1)"
    /></svg>Add Course
</button>

<div>
  <mat-form-field class="w-25">
    <mat-label>Search here</mat-label>
    <input matInput (keyup)="dataFilter($event)" placeholder="Search..." />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <mat-table [dataSource]="courselist" matSort>
      <!-- <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          ID
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.type }} </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="image">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Image </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.image}} </mat-cell>
            </ng-container> -->
      <ng-container matColumnDef="image">
        <mat-header-cell *matHeaderCellDef > Image </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <img src="https://englearn.sparkleweb.co.in/content/images/course/{{element.image}}" width="30px"/>
          <!-- <img src="{{element.image}}"> -->
          <!-- {{element.image}} -->
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
            </ng-container> -->

      <ng-container matColumnDef="shortDescription">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Short Description
        </mat-header-cell>

        <mat-cell *matCellDef="let element"
          ><span [innerHTML]=" (element.shortDescription.length>50)? (element.shortDescription | slice:0:40)+'..':(element.shortDescription)"></span>
     
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sortOrder">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Sort Order
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.sortOrder }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="topic" id="topic">
        <mat-header-cell *matHeaderCellDef> Topic </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <!-- <a [routerLink]="['/CourseTopic',element.id]">
                        <button
                            mat-raised-button color="primary">Topic List</button>
                        </a> -->

          <button
            (click)="clickToTopicList(element)"
            mat-raised-button
            color="primary"
          >
            Topic List
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a routerLink="/home/Course/{{ element.id }}"
            ><i class="fa fa-edit" style="font-size: 25px; color: blue"></i
          ></a>
          <span class="mx-1"></span
          ><i
            (click)="deleteRow(element.id)"
            class="fa fa-trash-o ml-2"
            style="font-size: 25px; color: red"
          ></i
        ></mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <!-- <mat-paginator [pageSize]="8" [pageSizeOptions]="[5, 8, 10, 25, 100]" showFirstLastButtons></mat-paginator> -->
    <!-- <mat-paginator
      (page)="handlePageEvent($event)"
      [length]="length"
      [pageSize]="pageSize"
      [showFirstLastButtons]="showFirstLastButtons"
      [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex"
      aria-label="Select page"
    >
    </mat-paginator> -->
    <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="totalRecords"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons"
    [pageIndex]="pageIndex-1"
  >
  </mat-paginator>
  </div>
</div>

<!--loading spinner code  -->
<mat-card
  *ngIf="_loaderService.isLoading | async"
  style="display: flex; justify-content: center; align-items: center"
>
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</mat-card>

<!-- <p>{{product_id}}</p> -->
