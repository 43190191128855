import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MyTopicService } from '../myTopic.service';
import Swal from 'sweetalert2';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';
// import * as xlsx from 'xlsx';


@Component({
  selector: 'app-topic-list',
  templateUrl: './topic-list.component.html',
  styleUrls: ['./topic-list.component.css']
})
export class TopicListComponent implements OnInit {




  displayedColumns: any[] = ['name','image','topicType', 'courseID', 'sortOrder', 'chapter', 'action'];
  dataSource: any;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  public errMsg = '';

  public courseId: any;

  public user: any = {};

  public courseNameTitle: any;
  topiclist2: any;

  constructor(private _router: Router, private _topicService: MyTopicService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) {
  }

  totalRecords = 0;
  pageSize = 5;
  pageIndex = 1;
  pageSizeOptions = [8, 10, 25, 50];
  showFirstLastButtons = true;
  // pagecount:any ="Selecte Page per Recode";
  // PageSizeCount(){
  //   this.pageSize=this.pagecount
  // }

  handlePageEvent(event: PageEvent) {
    debugger
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    // console.log(this.dataSource.paginator.pageIndex)
    // console.log(this.dataSource.paginator.length)
    // console.log(this.dataSource.paginator.pageSize)
    console.log(this.pageSize)
    console.log(this.pageIndex)
    this.getTopicList();


  }

  dataFilter(filterValue: any) {
    this.dataSource.filter = filterValue.value.trim().toLowerCase();

      // debugger
          this._topicService.getTopicList(this.courseId, this.pageSize, this.pageIndex ,this.dataSource.filter).subscribe((result) => {
      
            console.log(result, "topic");
            this.dataSource = result;
            this.dataSource = this.dataSource.courseTopicViewModelList
            localStorage.setItem('CourseId', JSON.stringify(this.courseId));
            this.totalRecords = this.dataSource[0].totalRecords;
      
            // this.dataSource = new MatTableDataSource(this.dataSource.courseTopicViewModelList);
            // this.dataSource.sort = this.sort;
            // this.dataSource.paginator = this.paginator;
          });
        
      
        
  }

  ngOnInit(): void {

    this.courseNameTitle = localStorage.getItem('CourseTitle');

    this.courseId = this._activatedRoute.snapshot.params.id;
    this.getTopicList();
  }

  getTopicList() {
debugger
    this._topicService.getTopicList(this.courseId, this.pageSize, this.pageIndex ,'').subscribe((result) => {

      console.log(result, "topic");
      this.dataSource = result;
      this.dataSource = this.dataSource.courseTopicViewModelList
      localStorage.setItem('CourseId', JSON.stringify(this.courseId));
      this.totalRecords = this.dataSource[0].totalRecords;

      this.dataSource = new MatTableDataSource(this.dataSource);
      this.dataSource.sort = this.sort;
      // this.dataSource.paginator = this.paginator;
    },
      error => {
        this.errMsg = error;
      });

  }

  clickToAddTopic() {
    commonRouting('home/CourseTopicAdd');
    // this._router.navigate(['/home/CourseTopicAdd']);
  }



  deleteRow(item: any) {

    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._topicService.deleteTopicList(item).subscribe((result: any) => {
          console.log(result, "delete");
          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });

  }


  clickToChapterList(obj: any) {
    localStorage.setItem('TopicName', obj.name);
    this._router.navigate(['/home/Chapter', obj.id]);
  }

}
