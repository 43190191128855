import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import {optimiseDeleteApi, optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyCourseService {
  
  public url = "https://englearn.sparkleweb.co.in/api/course";
  getCourseUrl = "https://englearn.sparkleweb.co.in/api/course/getcourselist";

  constructor(private _http: HttpClient) { }
  

  getCourseList(pageSize:number,pageIndex:number,search:any) {
    debugger;
    // return optimiseGetApi(this.url+'/getcourselist');
    return optimiseGetApi(`${this.url+"/getcourselist?PageIndex="}${pageIndex}&PageSize=${pageSize}&Search=${search}`);
  }

  // getCourseList()
  // {
  //   debugger;
  //   return this._http.get(this.getCourseUrl).pipe(catchError(this.errorHandler));
  // }

  errorHandler(error:HttpErrorResponse){
    return throwError(error.message || "Unknown Server Error");
  }

  postCourseList(data:any){
    debugger
    return optimisePostApi(this.url+"/addcourse",data);
  }

  deleteCourseList(id:any){
    return optimiseDeleteApi(`${this.url+"/deletecourse?id="}${id}`);
  }

  getList(id:number){
    debugger
    return optimiseEditApi(`${this.url+"/GetCourseById?id="}${id}`);   
  }

  updateCourseList(data:any){
    return optimiseUpdateApi(`${this.url+"/updatecourse"}`,data);
  }

}



// export function addition(a:any,b:any){
//   return a+b;
// }



