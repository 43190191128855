<h1 id="h1" class="text-center  my-4 py-4">Option List</h1>

<div class="row">

  <div class="text-center">
    <span id="span" class="text-muted">{{queName}}</span>
  </div>
  <div class="d-lg-flex justify-content-between align-items-center">

    <div class="">
      <button (click)="backToQuizList()" mat-raised-button color="primary">Back</button><span class=""></span>
      <button mat-raised-button color="warn" class="ms-5" (click)="deleteChekedList()">Delete selected</button>
    </div>

    <div class=" d-flex justify-content-between">

      <div>
        <input type="file" id="course" (change)="onFileChange($event)" class="" style="margin-right: -50px;cursor: pointer;">
        <button mat-raised-button id="course" color="accent" class="me-5" (click)="onImportExcelSubmit()"
          *ngIf=!show>Upload</button>
      </div>

      <a style="cursor: pointer" (click)="exportexcel()" class=" text-primary me-5">
        <mat-icon class="fs-1 "> download</mat-icon>
      </a>

      <div>
        <button (click)="addToOption()" mat-raised-button id="course" color="primary"><svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="rgba(255,255,255,1)" />
          </svg> Add Option</button>
      </div>

    </div>
  </div>
</div>

<div>
  <mat-form-field class="w-25">
    <mat-label>Search Here</mat-label>
    <input matInput placeholder="Search..." (keyup)="dataFilter($event.target)">
  </mat-form-field>


  <div class="mat-elevation-z8">
    <mat-table [dataSource]="optionList" matSort>

      <ng-container matColumnDef="checkList">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Select </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox class="ml-2" [(ngModel)]="element.checked" (change)="check($event,element.id)"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="text">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Text </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.text}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="correctAnswer">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Correct Answer </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.correctAnswer}} </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="isActive">
        <mat-header-cell *matHeaderCellDef mat-sort-header> isActive </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.isActive}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="isDeleted">
        <mat-header-cell *matHeaderCellDef mat-sort-header> isDeleted </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.isDeleted}} </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="quizID">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Quiz Id </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.quizID}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sortOrder">
        <mat-header-cell *matHeaderCellDef mat-sort-header> SortOrder </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef > Action </mat-header-cell>
        <mat-cell *matCellDef="let element"> <a routerLink="/home/updateOption/{{element.id}}"><i class="fa fa-edit"
              style="font-size:25px;color:blue"></i></a><span class="mx-1"></span> <i (click)="deleteRow(element.id)"
            class="fa fa-trash-o ml-2" style="font-size:25px;color:red"></i> </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>


    </mat-table>
    <!-- <mat-paginator [pageSize]="8" [pageSizeOptions]="[5,10,15,20]" showFirstLastButtons></mat-paginator> -->

    <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="totalRecords"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons"
    [pageIndex]="pageIndex-1"
  >
  </mat-paginator>
  </div>

</div>

<!-- loading spinner code -->
<mat-card *ngIf="_loaderService.isLoading | async" style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner 
    color="primary" 
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>