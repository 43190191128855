

<h1 class="text-center  my-5 py-4">Update Version</h1>


<div >

    <!-- <mat-form-field>
        <mat-label>Search here</mat-label>
        <input (keyup)="dataFilter($event.target)" matInput placeholder="Search...">
    </mat-form-field> -->

    <div class="mat-elevation-z8">

        <mat-table [dataSource]="updateVersionList1" matSort id="table">

            <!-- <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="appName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> App Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.appName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="version">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Version </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.version}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef > Action </mat-header-cell>
              <mat-cell *matCellDef="let element"> <a routerLink="/home/updatedVersion/{{element.id}}"><i class="fa fa-edit"
                    style="font-size:25px;color:blue"></i></a></mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons></mat-paginator> -->

    </div>

</div>

<!--loading spinner code  -->
<mat-card *ngIf="_loaderService.isLoading | async" style="display: flex; justify-content: center; align-items: center;">
    <mat-progress-spinner 
      color="primary" 
      mode="indeterminate">
    </mat-progress-spinner>
  </mat-card>