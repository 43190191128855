import { Component, OnInit } from '@angular/core';
import { MySubjectService } from '../mySubject.service';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.css']
})
export class SubjectComponent implements OnInit {

  constructor(private _subjectService : MySubjectService) { }

  data: any ;

  ngOnInit() {
    this._subjectService.data.next(2);
    this._subjectService.data.subscribe((e) => {
      this.data = e;
    }); // Doesn't get the current value
  }

  sendData(){
    this._subjectService.data.next(Math.random());
  }

}
