
<table class="table" border="1" style="width: 500px;">
  <thead>
    <tr>
      <th>ID</th>
      <th>Name</th>
      <th>Price</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor = "let x of product">
      <td>{{x.id}}</td>
      <td><a [routerLink]="['/product-list',x.id]">{{x.name}}</a></td>
      <td>{{x.price}}</td>
    </tr>
  </tbody>
  
</table>
