import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyChapterService } from '../myChapter.service';
import Swal from 'sweetalert2';
import { LoaderService } from 'src/app/loader/loader.service';
import { onSelectedFile, onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';

@Component({
  selector: 'app-add-chapter',
  templateUrl: './addChapter.component.html',
  styleUrls: ['./addChapter.component.css']
})
export class AddChapterComponent implements OnInit {

  previousImg: any;
  selectedFile: any;
  selectedFileName: any;
  isLoading = false;

  storeSortOrder: any;

  getSortOrder: any;

  parseSortOrder: any;

  autoIncreaseSortOrder: any;

  storeAutoIncreaseSortOrder: any;

  getAutoIncreaseSortOrder: any = localStorage.getItem('ChapterAutoIncrease');

  topicId :any = localStorage.getItem('TopicId');

  public TOPIC_ID = JSON.parse(this.topicId);

  public editMode:boolean = false;
  selectedFileShow: any;

  constructor(private _fb: FormBuilder, private _router: Router, private _chapterService: MyChapterService, private _activatedRoute: ActivatedRoute ,public _loaderService:LoaderService) { }

  addChapterList = this._fb.group({
    name: ['', [Validators.required]],
    image: [''],
    imageFile:[''],
    description: ['', [Validators.required]],
    type: ['', [Validators.required]],
    // listType: ['', [Validators.required]],
    courseTopicID: [this.TOPIC_ID, [Validators.required]],
    isActive: [true, [Validators.required]],
    isQuiz: [true, [Validators.required]],
    hindiName: ['', [Validators.required]],
    hindiDescription: ['', [Validators.required]],
    gujName: ['', [Validators.required]],
    gujDescription: ['', [Validators.required]],
    sortOrder: [(localStorage.getItem('ChapterSortOrder') == null ? 1 : this.getAutoIncreaseSortOrder), [Validators.required, Validators.pattern(/^[0-9]\d*$/)]]
  });

  public hasError = (controlName: string, errorName: string) => {
    return this.addChapterList.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {
    this._chapterService.getList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {
      this.editMode = true;
      this.previousImg = result.image;
      this.addChapterList = this._fb.group({
        id: [result['id']],
        name: [result['name'], [Validators.required]],
        image: [result['image']],
        imageFile: [result['imageFile']],
        description: [result['description'], [Validators.required]],
        type: [result['type'], [Validators.required]],
        // listType: [result['listType'], [Validators.required]],
        courseTopicID: [result['courseTopicID'], [Validators.required]],
        isActive: [result['isActive'], [Validators.required]],
        isQuiz:[result['isQuiz'], [Validators.required]],
        hindiName: [result['hindiName'], [Validators.required]],
        hindiDescription: [result['hindiDescription'], [Validators.required]],
        gujName: [result['gujName'], [Validators.required]],
        gujDescription: [result['gujDescription'], [Validators.required]],
        sortOrder: [result['sortOrder'], [Validators.required, Validators.pattern(/^[0-9]\d*$/)]]
      });
    });

  }

  onImageSubmit(event: any) {
    debugger;
    this.selectedFile = <File>event.target.files[0];
    this.selectedFileName = onSelectedFile(event);

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFileShow = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    // if(event.target.files){
    //   var reader = new FileReader();
    //   reader.readAsDataURL(event.target.files[0]);
    //   reader.onload=(e:any) => {
    //     this.selectedFile = e.target.result;
    //   }
    // }
  }

  onSubmit() {

    if (this.addChapterList.invalid) {
      return;
    }
    debugger
    if (this._activatedRoute.snapshot.params.id == "", this._activatedRoute.snapshot.params.id == "0", this._activatedRoute.snapshot.params.id == null) {

      const formData = new FormData();
      formData.append('name', this.addChapterList.value.name);
      formData.append('description', this.addChapterList.value.description);
      formData.append('type', this.addChapterList.value.type);
      // formData.append('listType', this.addChapterList.value.listType);
      formData.append('courseTopicID', this.addChapterList.value.courseTopicID);
      formData.append('isActive', this.addChapterList.value.isActive);
      formData.append('isQuiz', this.addChapterList.value.isQuiz);
      formData.append('courseTopicID', this.addChapterList.value.courseTopicID);
      formData.append('hindiName', this.addChapterList.value.hindiName);
      formData.append('hindiDescription', this.addChapterList.value.hindiDescription);
      formData.append('gujName', this.addChapterList.value.gujName);
      formData.append('gujDescription', this.addChapterList.value.gujDescription);
      formData.append('sortOrder', this.addChapterList.value.sortOrder);
      formData.append('image', this.selectedFileName);
      formData.append('imageFile', this.selectedFile);
      this._chapterService.postChapterList(formData).subscribe((result: any) => {
        console.log(result);

        this.storeSortOrder = localStorage.setItem('ChapterSortOrder', this.addChapterList.value.sortOrder);
        this.getSortOrder = localStorage.getItem('ChapterSortOrder');
        this.parseSortOrder = JSON.parse(this.getSortOrder);
        console.log(this.parseSortOrder);

        this.autoIncreaseSortOrder = this.parseSortOrder + 1;
        console.log(this.autoIncreaseSortOrder);
        this.storeAutoIncreaseSortOrder = localStorage.setItem('ChapterAutoIncrease', this.autoIncreaseSortOrder);

        if (result.status == "success") {
          onSweetAlertSuccess(result);
          this._router.navigate(['/home/Chapter', JSON.parse(this.topicId)]);
        } else if (result.status == "Already Exist") {
          onSweetAlertError(result);
        }
      })

    }
    else {
      this.addChapterList.controls['id'].setValue(this._activatedRoute.snapshot.params.id);
      const formData = new FormData();
      formData.append('id', this.addChapterList.value.id);
      formData.append('name', this.addChapterList.value.name);
      formData.append('description', this.addChapterList.value.description);
      formData.append('type', this.addChapterList.value.type);
      // formData.append('listType', this.addChapterList.value.listType);
      formData.append('courseTopicID', this.addChapterList.value.courseTopicID);
      formData.append('isActive', this.addChapterList.value.isActive);
      formData.append('isQuiz', this.addChapterList.value.isQuiz);
      formData.append('courseTopicID', this.addChapterList.value.courseTopicID);
      formData.append('hindiName', this.addChapterList.value.hindiName);
      formData.append('hindiDescription', this.addChapterList.value.hindiDescription);
      formData.append('gujName', this.addChapterList.value.gujName);
      formData.append('gujDescription', this.addChapterList.value.gujDescription);
      formData.append('sortOrder', this.addChapterList.value.sortOrder);
      formData.append('imageFile', this.selectedFile);
      if (this.selectedFileName == undefined) {
        formData.append('image', this.previousImg);
      } else {
        formData.append('image', this.selectedFileName);
      }

      this._chapterService.updateChapterList(formData).subscribe((result: any) => {
        console.log(result, "result");
        if (result.status == "success") {
          onSweetAlertSuccess(result);
          this._router.navigate(['/home/Chapter', JSON.parse(this.topicId)]);
        }
      })

    }

  }

  clicktobackList(){
    this._router.navigate(['/home/Chapter', JSON.parse(this.topicId)]);
  }

}

