

<h1 class="text-center  my-4 py-4">Feedback List</h1>


<div >

    <mat-form-field class="w-25">
        <mat-label>Search here</mat-label>
        <input (keyup)="dataFilter($event.target)" matInput placeholder="Search...">
    </mat-form-field>


    <div class="mat-elevation-z8">

        <mat-table [dataSource]="feedBackList" matSort id="table">

            <!-- <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="emailId">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.emailId}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="rating">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Rating </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.rating}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ratingText">
                <mat-header-cell *matHeaderCellDef mat-sort-header> RatingText </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.ratingText}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                <mat-cell *matCellDef="let element"> <i (click)="deleteRow(element.id)"
                        class="fa fa-trash-o" style="font-size:25px;color:red"></i></mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator [pageSize]="8" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons></mat-paginator>

    </div>

</div>

<!-- loading spinner -->
<div class="spinner" *ngIf="_loaderService.isLoading | async" style="display: flex; justify-content: center; align-items: center ; ">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>