import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MyProductService } from '../myProduct.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
  public product: any = [];

  public proId:any;

  sub:any;

  constructor(private _activatedRoute : ActivatedRoute, private _productService : MyProductService) { }

  ngOnInit() {
    this.sub = this._activatedRoute.paramMap.subscribe((params) => {
      console.log(params);
      this.proId = params.get('id');
      
      this.product = this._productService.getProductList().find(p=> p.id==this.proId);
    });
    
  }

}
