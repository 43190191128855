import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from 'src/app/loader/loader.service';
import { onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';
import Swal from 'sweetalert2';
import { MyFeedbackService } from '../myFeedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  displayedColumns: any[] = [ 'emailId', 'rating', 'ratingText', 'description', 'action'];
  feedBackList: any;
  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  isLoading= true;

  constructor(private _feedBackService: MyFeedbackService ,public _loaderService:LoaderService) { }

  ngOnInit() {
    this._feedBackService.getFeedBackList().subscribe((result: any) => {
      console.log(result, "feedback");
      this.isLoading =false;
      this.feedBackList = result;
      this.feedBackList = new MatTableDataSource(this.feedBackList);
      this.feedBackList.sort = this.sort;
      this.feedBackList.paginator = this.paginator;
    })
  }


  dataFilter(filterValue: any) {
    this.feedBackList.filter = filterValue.value.trim().toLowerCase();
  }

  deleteRow(item: any) {

    debugger
    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._feedBackService.deleteFeedBackList(item).subscribe((result: any) => {
          console.log(result, "delete");
          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });


  }


}
